import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import Navbar from "../../components/NavbarAdmin.js";
import Select from "react-select";
import {
    tokens,
    select_style,
    alert,
    GET,
    POST,
    format_date,
    float,
    DatePickerTH,
    getStorage,
    success_url,
    toFixed,
    total,
    days_between,
    days_between_return_car,
    status_rent
} from "../../components/CustomComponent.js";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import CAR from "../../assets/images/bike.png";
import Switch from "react-switch";
import Swal from "sweetalert2";
import SignatureCanvas from 'react-signature-canvas';
import Resizer from "react-image-file-resizer";

export default class ListBankAccount extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modal_create: false,
            modal_update: false,
            modal_delete: false,
            modal_signature: false,
            modal_picture2: false,

            page: 1,
            loading: false,

            data: null,
            data_bank: [],
            data_detail2: [],

            tab: "เงินสด",

            admin_name: getStorage("user") ? getStorage("user").full_name : "",
            rental_id: "",
            rental_number: "",
            address: "",
            birthday: null,
            district: "",
            email: "",
            full_name: "",
            id_card: "",
            is_active: true,
            member_code: "",
            member_id: "",
            member_status: 1,
            phone: "",
            picture: "",
            province: "",
            sub_district: "",
            username: "",
            verify_status: true,
            zip_code: "",
            picture: "",

            facebook: "",
            id_line: "",
            nationality: "",
            card_issue: "",
            date_issue: "",
            date_expire: "",
            temporary_accommodation: "",

            pick_up_date: new Date(),
            pick_up_time: "",
            pick_up_location: "",
            return_date: new Date(),
            return_time: "",
            return_location: "",

            rental_type: "",
            mileage: 0,
            oil_volume: 0,
            rental_price: 0,
            guarantee_type: 1,
            guarantee_amount: 0,
            equipment_fee: 0,
            discount_price: 0,
            delivery_price: 0,
            recommen_id: "",
            total_price: 0,
            signature: "",
            payment_type: "",
            discount_pay: 0,
            scale_days: 0,
            deposit_price: 0,
            deposit_pay: 0,

            total_price_equipment: 0,
            total_qty_equipment: 0,

            detail_picture: ["", "", "", "", "", "", "", "", "", ""],

            selected_bike: null,

            search_bike: "",
            rental_status: "",

            days: 0,
            point: 0,
            other_price: 0,
            total_pay: 0,
            total_pay_transfer: 0,
            total_pay_cradit: 0,
            change: 0,



            // ชำระ/ตอนคืน
            refund_cost: 0, // ยอดเงินคืนก่อนกำหนด
            other_expenses: 0, // ค่าใช้จ่ายอื่นๆ
            fuel_cost: 0, // ค่าน้ำมันรถ
            damage_cost: 0, // ค่าความเสียหาย
            service_charge: 0, // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: null, // วันคืนรถเดิม
            return_time: null, // เวลาคืนรถเดิม
            return_date_new: null, // วันคืนรถใหม่
            return_time_new: null, // เวลาคืนรถใหม่
            rental_type: "daily", // ประเภทการเช่า
            rental_price: 0, // ราคาเช่ารถ
            remark: "",
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            bank_name: "",
            payment_type: "1",
            payment_date: "",
            payment_time: "",
            payment_date_table: "",
            payment_time_table: "",
            damage_cost: 0,
            fuel_cost: 0,

            payment_picture: [],
            discount: 0,
            spend_points: 0, // แต้มที่ใช้
            change_points: 0, // แต้มแลกส่วนลด
            total_price: 0,
            change: 0,
            event_type: 1,

            total_pay_return: 0,

            use_point: false,
            point: 0,

            use_sk_wallet: false,
            sk_wallet: 0,

            overdue_booking: 0,

            wallet: null,
            agent_payment_type: "",
            agent_total_pay: 0,

            admin_signature: "",
            datetime_update: "",
            data_detail: null,
            discount_pay: 0,
            agent_id: "",

            admin_remark: "",
            service_charge_pay: 0,
            service_charge_overdue: 0,
            status_bill_agent: false,
            rental_price_pay: true,
            change_location: [],
            change_date: [],
            days: 0,
            hours: 0,
            minutes: 0,
            event_type: 0,
            guarantee_money: 0,
            tab_return: 0,

            return_remark: "",
            admin_return_name: ""
        }
    }
    async componentDidMount() {
        await this.GetBankAll();
        if (this.props.location.query.id) {
            await this.GetById(this.props.location.query.id);
            if (this.props.location.query.type !== "agent") {
                await this.GetListById(this.props.location.query.id);
            }
        }
        if (this.props.location.query.type === "agent") {
            this.setState({ rental_status: 0 });
            setTimeout(async () => {
                await this.GetListById(this.props.location.query.id);
            }, 10);
        }
    }
    GetBankAll = async () => {
        let data = {
            data_search: "",
        }
        let result = await GET(tokens, "v1/TH/bank-account/filter-search/page/0", data);
        if (result && result.status) {
            this.setState({ data_bank: result.data.data.map(e => { return { value: e.bank_id, label: e.account_number + " " + e.bank_name + " สาขา" + e.branch_name } }) })
        }
    }
    GetById = async (id) => {
        this.setState({ loading: true });
        let result = await GET(tokens, "v1/TH/rental-manage/detail/" + id, null);
        if (result && result.status) {
            let item = result.data.data; 
            let detail_picture = item.detail_picture.map(e => { return e.picture });
            for (let i = 0; i < 10; i++) {
                if (item.detail_picture.length <= i) {
                    detail_picture.push("")
                }
            }
            this.setState({
                rental_id: id,
                guarantee_money: item.guarantee_money,
                status_bill_agent: item.status_bill_agent,
                agent_id: item.agent_id,
                rental_number: item.rental_number,
                booking_number: item.booking_number,
                id_card: item.id_card,
                full_name: item.full_name,
                birthday: item.birthday,
                nationality: item.nationality,
                card_issue: item.card_issue,
                date_issue: item.date_issue,
                date_expire: item.date_expire,
                temporary_accommodation: item.temporary_accommodation,
                facebook: item.facebook,
                phone: item.phone,
                email: item.email,
                id_line: item.id_line,
                selected_bike: {
                    brand_name: item.brand_name,
                    model_name: item.model_name,
                    license_plate: item.license_plate,
                    chassis_number: item.chassis_number,
                    bike_color: item.bike_color,
                    cc_size: item.cc_size,
                    bike_year: item.bike_year,
                    title_picture: item.title_picture,
                    daily_price: item.daily_price,
                    bike_channel_name: item.bike_channel_name
                },
                pick_up_date: item.pick_up_date,
                pick_up_time: item.pick_up_time,
                pick_up_location: item.pick_up_location,
                return_date: item.return_date,
                return_time: item.return_time,
                return_location: item.return_location,

                rental_type: item.rental_type === "daily" ? { value: "daily", label: "รายวัน" } :
                    item.rental_type === "weekly" ? { value: "weekly", label: "รายสัปดาห์" } :
                        item.rental_type === "monthly" ? { value: "monthly", label: "รายเดือน" } : null,
                mileage: item.mileage,
                oil_volume: item.oil_volume,
                rental_price: item.rental_price,
                guarantee_type: item.guarantee_type === 1 ? { value: "1", label: "เงินประกัน" } :
                    item.guarantee_type === 2 ? { value: "2", label: "สำเนาบัตรประชาชน" } :
                        item.guarantee_type === 3 ? { value: "3", label: "บัตรประชาชนตัวจริง" } :
                            item.guarantee_type === 4 ? { value: "4", label: "บัตรข้าราชการ" } :
                                item.guarantee_type === 5 ? { value: "5", label: "บัตรนักศึกษา" } :
                                    item.guarantee_type === 6 ? { value: "6", label: "พาสปอร์ตตัวจริง" } :
                                        item.guarantee_type === 7 ? { value: "7", label: "สำเนาพาสปอร์ต" } :
                                            item.guarantee_type === 8 ? { value: "8", label: "ใบขับขี่" } :
                                                item.guarantee_type === 9 ? { value: "9", label: "ใบขับขี่ตัวจริง" } :
                                                    item.guarantee_type === 10 ? { value: "10", label: "เงินหยวน" } :
                                                        item.guarantee_type === 11 ? { value: "11", label: "เงินดอลล่า" } :
                                                            item.guarantee_type === 12 ? { value: "12", label: "เงินยูโร" } : null,
                guarantee_amount: item.guarantee_amount,
                equipment_fee: item.equipment_fee,
                discount_price: item.discount_price,
                delivery_price: item.delivery_price,
                recommen_id: item.recommen_id,
                total_price: item.total_price,
                signature: item.signature,
                equipment_list: item.equipment_list,
                total_price_equipment: item.total_price_equipment,
                overdue_booking: item.overdue_booking,
                total_pay_booking: item.total_pay_booking,
                total_qty_equipment: item.total_qty_equipment,
                detail_picture: detail_picture,
                title_picture: item.title_picture,
                rental_status: item.rental_status,
                other_price: item.other_price ? item.other_price : 0,
                datetime_update: item.datetime_update,

                days: days_between(new Date(format_date(item.pick_up_date, "yyyy,mm,dd", "en")), new Date(format_date(item.return_date, "yyyy,mm,dd", "en"))),

                array_price: {
                    daily_price: item.daily_price,
                    weekly_price: item.weekly_price,
                    monthly_price: item.monthly_price
                },

                wallet: {
                    point: item.points,
                    sk_wallet: item.sk_wallet
                },
                status_pay2: { value: "คิดเงิน", label: "คิดเงิน" },
                event_type: item.event_type
            })

            if (this.props.location.query.type === "scale") {
                this.setState({ rental_price: 0 })
            }
        }
        this.setState({ loading: false });
    }
    GetListById = async (id) => {
        this.setState({ loading: true });
        let result = await GET(tokens, "v1/TH/cashier/detail/" + id, null);
        if (result && result.status) {
 
            if (this.state.rental_status === 0) {
                let agent = result.data.data.filter(e => e.event_agent).map(e => { return { ...e, ...e.event_agent } });
                if (agent.length !== 0) {
                    this.setState({
                        agent_total_pay: total(agent, "agent_total_pay"),
                        agent_payment_type: agent[0].agent_payment_type === 1 ? { value: "1", label: "เงินสด" } : { value: "2", label: "เงินโอน" },
                        admin_signature: agent[0].admin_signature
                    })
                }
            } else {
                let array = result.data.data.filter(e => e.event_type === 2 && e.admin_remark !== "แคชเชียร์ ยืนยันการชำระเงิน" && e.admin_remark !== "แคชเชียร์ ขยายสัญญาไม่อนุมัติ" && e.admin_remark !== "พนักงาน ขยายสัญญารอเงินมัดจำ" && e.admin_remark !== "พนักงาน ขยายสัญญาไม่อนุมัติ" && e.admin_remark !== "")
                this.setState({ data_detail: array, data_detail2: result.data.data })
                let item = result.data.data;
                item = item.length !== 0 ? item[item.length - 1] : null;
                if (item) {

                    if (this.state.rental_status === 1) {
                        if (item.event_type === 1) {
                            this.setState({
                                tab: item.payment_type === 1 ? "เงินสด" :
                                    item.payment_type === 3 ? "Credit Cards" :
                                        item.payment_type === 2 ? "ธนาคารไทย" : "",
                                payment_type: item.payment_type,
                                total_pay: item.total_pay + this.state.total_pay_booking,
                                total_pay_transfer: item.total_pay_transfer,
                                total_pay_cradit: item.total_pay_cradit,
                                change: item.change,
                                bank_name: this.state.data_bank.filter(e => e.value === item.bank_name)[0],
                                payment_date: item.payment_date,
                                payment_time: item.payment_time,
                                payment_picture: item.payment_picture,
                                point: item.spend_points,
                                sk_wallet: item.spend_wallet,
                                use_point: item.spend_points !== 0,
                                use_sk_wallet: item.spend_wallet !== 0,
                                service_charge: 0
                            })
                        } else if (item.event_type === 2) {
                            this.setState({
                                tab: item.payment_type === 1 ? "เงินสด" :
                                    item.payment_type === 3 ? "Credit Cards" :
                                        item.payment_type === 2 ? "ธนาคารไทย" : "",
                                payment_type: item.payment_type,
                                total_pay: item.total_pay,
                                total_pay_transfer: item.total_pay_transfer,
                                total_pay_cradit: item.total_pay_cradit,
                                change: item.change,
                                bank_name: this.state.data_bank.filter(e => e.value === item.bank_name)[0],
                                payment_date: item.payment_date,
                                payment_time: item.payment_time,
                                rental_price: 0,
                                deposit_price: item.total_pay + item.total_pay_transfer + item.total_pay_cradit + item.spend_wallet + (item.spend_points * 20),
                                guarantee_amount: 0,
                                total_price_equipment: 0,
                                payment_picture: item.payment_picture,
                                delivery_price: 0,
                                other_price: 0,
                                total_pay_booking: 0,
                                discount_price: 0,
                                point: item.spend_points,
                                sk_wallet: item.spend_wallet,
                                use_point: item.spend_points !== 0,
                                use_sk_wallet: item.spend_wallet !== 0,
                                service_charge: 0
                            })
                        } else if (item.event_type === 4) {
                            this.setState({

                                rental_price_pay: item.change < 0 ? false : true,
                                rental_price: item.total_price - item.event_return.service_charge,
                                service_charge: item.event_return.service_charge,
                                tab: item.payment_type === 1 ? "เงินสด" :
                                    item.payment_type === 3 ? "Credit Cards" :
                                        item.payment_type === 2 ? "ธนาคารไทย" : "เงินสด",
                                payment_type: item.payment_type,
                                total_pay: item.total_pay,
                                total_pay_transfer: item.total_pay_transfer,
                                total_pay_cradit: item.total_pay_cradit,
                                change: item.change,
                                bank_name: this.state.data_bank.filter(e => e.value === item.bank_name)[0],
                                payment_date: item.payment_date,
                                payment_time: item.payment_time,
                                payment_picture: item.payment_picture,
                                point: item.spend_points,
                                sk_wallet: item.spend_wallet,
                                use_point: item.spend_points !== 0,
                                use_sk_wallet: item.spend_wallet !== 0,

                                guarantee_amount: 0,
                                total_price_equipment: 0,
                                delivery_price: 0,
                                other_price: 0,
                                total_pay_booking: 0,
                                deposit_price: 0,
                                discount_price: 0,
                            })
                        }
                    } else if (this.state.rental_status === 5 || this.state.rental_status === 12) {
                        let item = result.data.data;
                        item = item[item.length - 1];
                        if (item.event_extend) {
                            this.setState({
                                return_date: item.event_extend.return_date,
                                return_time: item.event_extend.return_time,
                                return_date_new: item.event_extend.return_date_new,
                                return_time_new: item.event_extend.return_time_new,
                                bank_name: item.bank_name ? this.state.data_bank.filter(e => e.value === item.bank_name)[0] : "",
                                payment_date: item.payment_date,
                                payment_time: item.payment_time,
                                payment_type: item.payment_type,
                                payment_picture: item.payment_picture,
                                total_pay: this.state.rental_status === 5 ? item.event_extend.rental_price : item.total_pay,
                                total_pay_transfer: item.total_pay_transfer,
                                total_pay_cradit: item.total_pay_cradit,
                                rental_price: item.event_extend.rental_price,
                                point: item.spend_points,
                                sk_wallet: item.spend_wallet,
                                use_point: item.spend_points !== 0,
                                use_sk_wallet: item.spend_wallet !== 0,
                                service_charge: 0,
                                status_pay2: { value: "คิดเงิน", label: "คิดเงิน" },
                                discount_pay: item.event_extend.discount,
                            })
                            if (this.state.rental_status === 5) {
                                let days = days_between(
                                    new Date(format_date(item.event_extend.return_date, "yyyy,mm,dd", "en")),
                                    new Date(format_date(item.event_extend.return_date_new, "yyyy,mm,dd", "en")),
                                    item.event_extend.return_time,
                                    item.event_extend.return_time_new
                                )
                                let hours_price = 0;
                                let rental_price = 0;
                                if (days.hour === 0 && days.minute >= 0) {
                                    //ไม่ต้องหัก 
                                } else if (days.hour === 1 && days.minute >= 0) {
                                    if (this.state.array_price.daily_price < 400) {
                                        hours_price = 50; //หัก 50 บาท
                                    } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                        hours_price = 100; //หัก 100 บาท
                                    } else if (this.state.array_price.daily_price > 700) {
                                        hours_price = 200; //หัก 200 บาท
                                    }
                                } else if (days.hour === 2 && days.minute >= 0) {
                                    if (this.state.array_price.daily_price < 400) {
                                        hours_price = 100; //หัก 50 บาท
                                    } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                        hours_price = 200; //หัก 100 บาท
                                    } else if (this.state.array_price.daily_price > 700) {
                                        hours_price = 400; //หัก 200 บาท
                                    }
                                } else if (days.hour === 3 && days.minute >= 0) {
                                    if (this.state.array_price.daily_price < 400) {
                                        hours_price = 150; //หัก 50 บาท
                                    } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                        hours_price = 300; //หัก 100 บาท
                                    } else if (this.state.array_price.daily_price > 700) {
                                        hours_price = 600; //หัก 200 บาท
                                    }
                                } else if (days.hour >= 4) {
                                    hours_price = this.state.array_price.daily_price;//หัก daily_price
                                }
                                setTimeout(() => {
                                    if (this.state.selected_bike) {
                                        if (this.state.rental_type.value === "daily") {
                                            rental_price = this.state.array_price.daily_price * days.day;
                                            rental_price += hours_price;
                                        } else if (this.state.rental_type.value === "weekly") {
                                            if (this.state.array_price.weekly_price === 0) {
                                                rental_price = this.state.array_price.daily_price * days.day;
                                                rental_price += hours_price;
                                            } else {
                                                if (days.day >= 7) {
                                                    rental_price += Number((days.day / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                    rental_price += (days.day % 7) * this.state.array_price.daily_price;
                                                    if (days.day % 7 === 0) {
                                                        rental_price += hours_price;
                                                    }
                                                } else {
                                                    rental_price = this.state.array_price.weekly_price;
                                                }
                                            }
                                        } else if (this.state.rental_type.value === "monthly") {
                                            if (this.state.array_price.monthly_price === 0) {
                                                rental_price = this.state.array_price.daily_price * days.day;
                                                rental_price += hours_price;
                                            } else {
                                                if (days.day >= 30) {
                                                    rental_price += Number((days.day / 30).toString().split(".")[0]) * this.state.array_price.monthly_price;
                                                    rental_price += Number(((days.day % 30) / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                    rental_price += ((days.day % 30) % 7) * this.state.array_price.daily_price;
                                                    if (days.day % 30 === 0) {
                                                        rental_price += hours_price;
                                                    }
                                                } else {
                                                    rental_price = this.state.array_price.monthly_price;
                                                }
                                            }
                                        }
                                    }
                                    this.setState({ rental_price: rental_price })
                                }, 10);
                            }
                        }
                    } else if (this.state.rental_status === 6) {
                        this.setState({
                            refund_cost: item.event_return.refund_cost,
                            rental_price_overdue: item.event_return.rental_price_overdue,
                            other_expenses: item.event_return.other_expenses,
                            fuel_cost: item.event_return.fuel_cost,
                            damage_cost: item.event_return.damage_cost,
                            service_charge: item.event_return.service_charge,

                            tab: item.payment_type === 1 ? "เงินสด" :
                                item.payment_type === 3 ? "Credit Cards" :
                                    item.payment_type === 2 ? "ธนาคารไทย" : "",
                            payment_type: item.payment_type,
                            total_pay: item.total_pay,
                            total_pay_transfer: item.total_pay_transfer,
                            total_pay_cradit: item.total_pay_cradit,
                            change: item.change,
                            bank_name: this.state.data_bank.filter(e => e.value === item.bank_name)[0],
                            payment_date: item.payment_date,
                            payment_time: item.payment_time,
                            payment_picture: item.payment_picture,
                            point: item.spend_points,
                            sk_wallet: item.spend_wallet,
                            use_point: item.spend_points !== 0,
                            use_sk_wallet: item.spend_wallet !== 0,
                            discount_pay: item.discount,
                            remark: item.event_return.return_remark
                        })
                    } else if (this.state.rental_status === 9) {
                        this.setState({
                            tab: item.payment_type === 1 ? "เงินสด" :
                                item.payment_type === 3 ? "Credit Cards" :
                                    item.payment_type === 2 ? "ธนาคารไทย" : "",
                            admin_remark: item.admin_remark,
                            total_pay: item.total_pay,
                            total_pay_transfer: item.total_pay_transfer,
                            total_pay_cradit: item.total_pay_cradit,
                            payment_type: item.payment_type,
                            payment_picture: item.payment_picture,
                            payment_date: item.payment_date,
                            payment_time: item.payment_time,
                            status_pay: { value: "ชำระ", label: "ชำระ" },
                            old_price: Number(item.admin_remark.split("||")[1]),
                            bank_name: this.state.data_bank.filter(e => e.value === item.bank_name)[0],
                            service_charge: 0
                        })
                    }
                    this.setState({
                        admin_name: item.admin_name,
                        admin_return_name: item.admin_return_name,
                        admin_remark: item.admin_remark
                    })
                    this.setState({ change_location: result.data.data.filter(e => e.admin_remark === "พนักงาน เปลี่ยนแปลงข้อมูล" || e.admin_remark === "พนักงาน เปลี่ยนแปลงข้อมูลค้างชำระ") })

                    this.setState({ change_date: result.data.data.filter(e => e.admin_remark === "พนักงาน เปลี่ยนวันเวลาคืนรถ") })
                }
                if (this.props.location.query.type === "return") {
                    let event4 = result.data.data.filter(e => e.event_type === 4 && e.admin_remark === "พนักงาน เปลี่ยนแปลงข้อมูล");
                    this.setState({ service_charge_pay: total(event4, "total_price") });
                    let event4_overdue = result.data.data.filter(e => e.event_type === 4 && e.admin_remark === "พนักงาน เปลี่ยนแปลงข้อมูลค้างชำระ");
                    this.setState({ service_charge_overdue: total(event4_overdue, "total_price") });
                }
                setTimeout(async () => {
                    if (this.state.rental_status !== 6) {
                        await this.calculate();
                    }
                    await this.getDay();
                }, 10);
                let agent = result.data.data.filter(e => e.event_agent).map(e => { return { ...e, ...e.event_agent } });
                if (agent.length !== 0) {
                    this.setState({
                        agent_total_pay: total(agent, "agent_total_pay"),
                        agent_payment_type: agent[0].agent_payment_type === 1 ? { value: "1", label: "เงินสด" } : { value: "2", label: "เงินโอน" },
                        admin_signature: agent[0].admin_signature
                    })
                }
            }
        }
        this.setState({ loading: false });
    }
    getDay = () => {
        let days = days_between(
            new Date(format_date(this.state.pick_up_date, "yyyy,mm,dd", "en")),
            new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")),
            this.state.pick_up_time,
            this.state.return_time
        )
        this.setState({
            days: days.day,
            hours: days.hour,
            minutes: days.minute
        });
    }
    calculate = () => {
        let total_rent_price = this.state.rental_price + (this.state.data_detail2 ? total(this.state.data_detail2.filter(e => e.admin_remark === "แคชเชียร์ ขยายสัญญาชำระ" || (e.admin_remark === "แคชเชียร์ ยืนยันการชำระเงิน" && e.event_type === 2)), "total_pay") + total(this.state.data_detail2.filter(e => e.admin_remark === "แคชเชียร์ ขยายสัญญาชำระ" || (e.admin_remark === "แคชเชียร์ ยืนยันการชำระเงิน" && e.event_type === 2)), "total_pay_transfer") + total(this.state.data_detail2.filter(e => e.admin_remark === "แคชเชียร์ ขยายสัญญาชำระ" || (e.admin_remark === "แคชเชียร์ ยืนยันการชำระเงิน" && e.event_type === 2)), "total_pay_cradit") : 0);
        let item = {
            pick_up_date: this.state.pick_up_date + "T" + this.state.pick_up_time,
            return_date: this.state.return_date + "T" + this.state.return_time,
            daily_price: Number(this.state.array_price.daily_price),
            weekly_price: Number(this.state.array_price.weekly_price),
            monthly_price: Number(this.state.array_price.monthly_price),
            rental_type: this.state.rental_type.value
        }
        if (this.state.change_date.length !== 0) {
            item.return_date = this.state.change_date[0].event_extend.remark;
        }

        if (this.state.change_location.length !== 0) {
            if (this.state.change_location[0].event_return.remark !== "") {
                item.daily_price = Number(JSON.parse(this.state.change_location[0].event_return.remark).daily_price);
                item.weekly_price = Number(JSON.parse(this.state.change_location[0].event_return.remark).weekly_price);
                item.monthly_price = Number(JSON.parse(this.state.change_location[0].event_return.remark).monthly_price);
            }
        }

        let date = new Date();
        let total_day = days_between(new Date(format_date(item.pick_up_date, "yyyy,mm,dd", "en")), new Date(format_date(item.return_date, "yyyy,mm,dd", "en")), format_date(item.pick_up_date, "hh:mm"), format_date(item.return_date, "hh:mm"));
        let rental_day = days_between(new Date(format_date(item.pick_up_date, "yyyy,mm,dd", "en")), new Date(format_date(date, "yyyy,mm,dd", "en")), format_date(item.pick_up_date, "hh:mm"), format_date(date, "hh:mm"));
        let left_day = days_between_return_car(new Date(format_date(date, "yyyy,mm,dd", "en")), new Date(format_date(item.return_date, "yyyy,mm,dd", "en")), format_date(date, "hh:mm"), format_date(item.return_date, "hh:mm"));
        let left_day2 = null;
        if (Number(this.state.pick_up_time.slice(0, 5).replaceAll(":", "")) < Number(this.state.return_time.slice(0, 5).replaceAll(":", ""))) {
            left_day2 = days_between_return_car(new Date(format_date(item.return_date, "yyyy,mm,dd", "en")), new Date(format_date(date, "yyyy,mm,dd", "en")), format_date(item.return_date, "hh:mm"), format_date(date, "hh:mm"))
        } else {
            left_day2 = days_between_return_car(new Date(format_date(item.return_date, "yyyy,mm,dd", "en")), new Date(format_date(date, "yyyy,mm,dd", "en")), format_date(item.pick_up_date, "hh:mm"), format_date(date, "hh:mm"))
        }
        if (left_day.type === "ก่อนกำหนด") {
            let total = 0;
            let discount = 0;

            if (rental_day.day === 0) {
                discount = item.daily_price;
            } else {
                // การหักเงิน
                if (rental_day.hour === 0 && rental_day.minute >= 0) {
                    //ไม่ต้องหัก 
                } else if (rental_day.hour === 1 && rental_day.minute >= 0) {
                    discount = 50; //หัก 50 บาท
                } else if (rental_day.hour === 2 && rental_day.minute >= 0) {
                    discount = 100; //หัก 100 บาท
                } else if (rental_day.hour === 3 && rental_day.minute >= 0) {
                    discount = 150; //หัก 150 บาท
                } else if (rental_day.hour >= 4) {
                    discount = item.daily_price;//หัก daily_price
                }
            }
            if (item.rental_type === "daily") {
                total = item.daily_price * rental_day.day;
            } else if (item.rental_type === "weekly") {
                if (item.weekly_price === 0) {
                    total = item.daily_price * rental_day.day;
                } else {
                    total += Number((rental_day.day / 7).toString().split(".")[0]) * item.weekly_price;
                    if ((rental_day.day % 7) * item.daily_price < item.weekly_price) {
                        total += (rental_day.day % 7) * item.daily_price;
                    } else {
                        total += item.weekly_price;
                    }
                }
            } else if (item.rental_type === "monthly") {
                if (item.monthly_price === 0 && item.weekly_price === 0) {
                    total = item.daily_price * rental_day.day;
                } else if (item.monthly_price === 0 && item.weekly_price !== 0) {
                    total += Number((rental_day.day / 7).toString().split(".")[0]) * item.weekly_price;
                    if ((rental_day.day % 7) * item.daily_price < item.weekly_price) {
                        total += (rental_day.day % 7) * item.daily_price;
                    } else {
                        total += item.weekly_price;
                    }
                } else {
                    if (((rental_day.day % 30) % 7) * item.daily_price < item.weekly_price) {
                        total += ((rental_day.day % 30) % 7) * item.daily_price;
                    } else {
                        total += item.weekly_price;
                    }
                    if ((Number(((rental_day.day % 30) / 7).toString().split(".")[0]) * item.weekly_price) + total < item.monthly_price) {
                        total += Number(((rental_day.day % 30) / 7).toString().split(".")[0]) * item.weekly_price;
                    } else {
                        total = item.monthly_price;
                    }
                    total += Number((rental_day.day / 30).toString().split(".")[0]) * item.monthly_price;
                }
            }
            this.setState({ refund_cost: total_rent_price - (total + discount) > 0 ? total_rent_price - (total + discount) : 0 })
        } else if (left_day.type === "เกินกำหนด") {
            let total = 0;
            let discount = 0;
            let rental_price_overdue = 0;

            if (left_day2.hour === 0 && left_day2.minute >= 0) {
                //ไม่ต้องหัก
            } else if (left_day2.hour === 1 && left_day2.minute >= 0) {
                if (item.daily_price < 400) {
                    discount = 50; //หัก 50 บาท
                } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                    discount = 100; //หัก 100 บาท
                } else if (item.daily_price > 700) {
                    discount = 200; //หัก 200 บาท
                }
            } else if (left_day2.hour === 2 && left_day2.minute >= 0) {
                if (item.daily_price < 400) {
                    discount = 100; //หัก 50 บาท
                } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                    discount = 200; //หัก 100 บาท
                } else if (item.daily_price > 700) {
                    discount = 400; //หัก 200 บาท
                }
            } else if (left_day2.hour === 3 && left_day2.minute >= 0) {
                if (item.daily_price < 400) {
                    discount = 150; //หัก 50 บาท
                } else if (item.daily_price >= 400 && item.daily_price <= 700) {
                    discount = 300; //หัก 100 บาท
                } else if (item.daily_price > 700) {
                    discount = 600; //หัก 200 บาท
                }
            } else if (left_day2.hour >= 4) {
                discount = item.daily_price;//หัก daily_price
            }
            if (item.rental_type === "daily") {
                total = item.daily_price * left_day2.day;
                if (format_date(new Date(), "yyyy-mm-dd") === format_date(item.return_date, "yyyy-mm-dd")) {
                    if (Number(this.state.return_time.slice(0, 5).replaceAll(":", "")) < Number(this.state.pick_up_time.slice(0, 5).replaceAll(":", "")) && Number(format_date(new Date(), "hh:mm").replaceAll(":", "")) < Number(this.state.pick_up_time.slice(0, 5).replaceAll(":", ""))) {
                        discount = 0;
                    }
                }
            } else if (item.rental_type === "weekly") {
                if (item.weekly_price === 0) {
                    total = item.daily_price * left_day2.day;
                } else {
                    if ((total_day.day % 7) < 7 && (total_day.day % 7) !== 0) {
                        let max_day = new Date(new Date(format_date(item.return_date, "yyyy,mm,dd", "en")).setDate(new Date(format_date(item.return_date, "yyyy,mm,dd", "en")).getDate() + (7 - (total_day.day % 7))));
                        if (max_day.getDate() < new Date().getDate()) {
                            let left_day3 = days_between_return_car(new Date(format_date(max_day, "yyyy,mm,dd", "en")), new Date(format_date(date, "yyyy,mm,dd", "en")), this.state.return_time.slice(0, 5), format_date(date, "hh:mm"));
                            total += Number((left_day3.day / 7).toString().split(".")[0]) * item.weekly_price;
                            if ((left_day3.day % 7) * item.daily_price < item.weekly_price) {
                                total += (left_day3.day % 7) * item.daily_price;
                            } else {
                                total += item.weekly_price;
                            }
                        } else {
                            total = 0;
                            rental_price_overdue += Number((rental_day.day / 7).toString().split(".")[0]) * item.weekly_price;
                            if ((rental_day.day % 7) * item.daily_price < item.weekly_price) {
                                rental_price_overdue += (rental_day.day % 7) * item.daily_price;
                            } else {
                                rental_price_overdue += item.weekly_price;
                            }
                            this.setState({ 
                                refund_cost: total_rent_price - rental_price_overdue - discount < 0 ? 0 : total_rent_price - rental_price_overdue - discount,
                                rental_price_overdue: rental_day.day < 7 ? 0 : rental_price_overdue - total_rent_price
                             })
                            return
                        }
                    } else {
                        total += Number((left_day2.day / 7).toString().split(".")[0]) * item.weekly_price;
                        if ((left_day2.day % 7) * item.daily_price < item.weekly_price) {
                            total += (left_day2.day % 7) * item.daily_price;
                        } else {
                            total += item.weekly_price;
                        }
                    }
                }
            } else if (item.rental_type === "monthly") {
                if (item.monthly_price === 0 && item.weekly_price === 0) {
                    total = item.daily_price * left_day2.day;
                } else if (item.monthly_price === 0 && item.weekly_price !== 0) {
                    if ((total_day.day % 7) < 7 && (total_day.day % 7) !== 0) {
                        let max_day = new Date(new Date(format_date(item.return_date, "yyyy,mm,dd", "en")).setDate(new Date(format_date(item.return_date, "yyyy,mm,dd", "en")).getDate() + (7 - (total_day.day % 7))));
                        if (max_day.getDate() < new Date().getDate()) {
                            let left_day3 = days_between_return_car(new Date(format_date(max_day, "yyyy,mm,dd", "en")), new Date(format_date(date, "yyyy,mm,dd", "en")), this.state.return_time.slice(0, 5), format_date(date, "hh:mm"));
                            total += Number((left_day3.day / 7).toString().split(".")[0]) * item.weekly_price;
                            if ((left_day3.day % 7) * item.daily_price < item.weekly_price) {
                                total += (left_day3.day % 7) * item.daily_price;
                            } else {
                                total += item.weekly_price;
                            }
                        } else {
                            total = 0;
                            rental_price_overdue += Number((rental_day.day / 7).toString().split(".")[0]) * item.weekly_price;
                            if ((rental_day.day % 7) * item.daily_price < item.weekly_price) {
                                rental_price_overdue += (rental_day.day % 7) * item.daily_price;
                            } else {
                                rental_price_overdue += item.weekly_price;
                            }
                            this.setState({ 
                                refund_cost: total_rent_price - rental_price_overdue - discount < 0 ? 0 : total_rent_price - rental_price_overdue - discount,
                                rental_price_overdue: rental_day.day < 7 ? 0 : rental_price_overdue - total_rent_price
                             })
                            return
                        }
                    } else {
                        total += Number((left_day2.day / 7).toString().split(".")[0]) * item.weekly_price;
                        if ((left_day2.day % 7) * item.daily_price < item.weekly_price) {
                            total += (left_day2.day % 7) * item.daily_price;
                        } else {
                            total += item.weekly_price;
                        }
                    }
                } else {
                    // ต้องทำ********************************
                    if ((total_day.day % 30) < 30 && (total_day.day % 30) !== 0) {
                        let max_day = new Date(new Date(format_date(item.return_date, "yyyy,mm,dd", "en")).setDate(new Date(format_date(item.return_date, "yyyy,mm,dd", "en")).getDate() + (7 - (total_day.day % 7))));
                        // if (max_day.getDate() < new Date().getDate()) {
                        //     let left_day3 = days_between_return_car(new Date(format_date(max_day, "yyyy,mm,dd", "en")), new Date(format_date(date, "yyyy,mm,dd", "en")), this.state.return_time.slice(0, 5), format_date(date, "hh:mm"));

                        //     total += Number((left_day3.day / 7).toString().split(".")[0]) * item.weekly_price;
                        //     if ((left_day3.day % 7) * item.daily_price < item.weekly_price) {
                        //         total += (left_day3.day % 7) * item.daily_price;
                        //     } else {
                        //         total += item.weekly_price;
                        //     }
                        // } else {
                        //     total = 0;
                        //     rental_price_overdue += Number((rental_day.day / 7).toString().split(".")[0]) * item.weekly_price;
                        //     if ((rental_day.day % 7) * item.daily_price < item.weekly_price) {
                        //         rental_price_overdue += (rental_day.day % 7) * item.daily_price;
                        //     } else {
                        //         rental_price_overdue += item.weekly_price;
                        //     }
                        //     this.setState({ refund_cost: total_rent_price - rental_price_overdue < 0 ? 0 : total_rent_price - rental_price_overdue })


                        // }
                    } else {
                        if (((left_day2.day % 30) % 7) * item.daily_price < item.weekly_price) {
                            total += ((left_day2.day % 30) % 7) * item.daily_price;
                        } else {
                            total += item.weekly_price;
                        }
                        if ((Number(((left_day2.day % 30) / 7).toString().split(".")[0]) * item.weekly_price) + total < item.monthly_price) {
                            total += Number(((left_day2.day % 30) / 7).toString().split(".")[0]) * item.weekly_price;
                        } else {
                            total = item.monthly_price;
                        }
                        total += Number((left_day2.day / 30).toString().split(".")[0]) * item.monthly_price;
                    }
                }
            }
            this.setState({ rental_price_overdue: (total + discount) > 0 ? (total + discount) : 0 })
        }
    }
    Update01 = async () => {
        console.log("Update01")
        this.setState({ loading: true });
        let point = 0;
        if (Number(this.state.point) % 10 !== 0) {
            point = Number(this.state.point) - Number(this.state.point) % 10;
        } else {
            point = Number(this.state.point)
        }
        let data = {
            rental_id: this.state.rental_id,
            rental_status: 3,
            // ชำระ/ตอนคืน
            refund_cost: 0, // ยอดเงินคืนก่อนกำหนด
            other_expenses: 0, // ค่าใช้จ่ายอื่นๆ
            fuel_cost: 0, // ค่าน้ำมันรถ
            damage_cost: 0, // ค่าความเสียหาย
            service_charge: this.state.service_charge_pay+this.state.service_charge, // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: this.state.return_date, // วันคืนรถเดิม
            return_time: this.state.return_time, // เวลาคืนรถเดิม
            return_date_new: this.state.return_date_new, // วันคืนรถใหม่
            return_time_new: this.state.return_time_new, // เวลาคืนรถใหม่
            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily", // ประเภทการเช่า
            rental_price: Number(this.state.rental_price), // ราคาเช่ารถ
            remark: this.state.remark,
            // ชำระ/ตอนเปลี่ยนรถ
            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_location: this.state.pick_up_location,
            return_location: this.state.return_location,
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            overdue_booking: 0,
            bank_name: this.state.bank_name ? this.state.bank_name.value : "",
            payment_type: this.state.payment_type,
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : format_date(new Date(), "yyyy-mm-dd", "en"),
            payment_time: this.state.payment_time ? this.state.payment_time : format_date(new Date(), "hh:mm:ss"),
            payment_picture: this.state.payment_picture,
            discount: 0,
            return_points: 0, // แต้มคืนให้ลูกค้า
            spend_points: 0, // แต้มที่ใช้
            change_points: 0, // แต้มแลกส่วนลด
            total_price: (this.state.rental_price + this.state.guarantee_amount + this.state.total_price_equipment + this.state.delivery_price + this.state.other_price - this.state.discount_price + this.state.service_charge_pay + this.state.deposit_price),
            total_pay: this.state.total_pay,
            total_pay_transfer: this.state.total_pay_transfer,
            total_pay_cradit: this.state.total_pay_cradit,
            change: 0,
            event_type: this.state.deposit_price ? 2 : this.state.admin_remark.includes("เปลี่ยนแปลงข้อมูล") ? 4 : 1,
            payment_list: [],
            return_wallet: 0,
            spend_wallet: 0,

            admin_signature: "",

            agent_total_pay: 0,
            agent_payment_type: "",
            // เงินเข้า
            total_income: ((this.state.rental_price + 
                this.state.total_price_equipment + 
                this.state.delivery_price + 
                this.state.other_price + 
                this.state.deposit_price + 
                this.state.service_charge_pay + 
                this.state.service_charge) - 
                this.state.discount_price),
            admin_remark: "แคชเชียร์ ยืนยันการชำระเงิน",
            admin_name: getStorage("user").full_name,
            admin_return_name: this.state.admin_return_name,
            return_remark: "",
            last_return_date: ""
        } 
        let result = await POST(tokens, "v1/TH/rental-manage/payment", data);
        if (result && result.status) {
            let cashier_summary = getStorage("cashier_summary");
            cashier_summary.rental_price[0] += Number(this.state.rental_price); //ค่าเช่า
            cashier_summary.guarantee_amount[0] += Number(this.state.guarantee_amount); //ค่าค้ำประกัน
            cashier_summary.equipment_fee[0] += Number(this.state.total_price_equipment); //ค่าอุปกรณ์
            cashier_summary.delivery_price[0] += Number(this.state.delivery_price); //ค่าขนส่ง
            cashier_summary.fuel_cost[0] += 0; //ค่าน้ำมัน
            cashier_summary.damage_cost[0] += 0; //ค่าเสียหาย
            cashier_summary.service_charge[0] += Number(this.state.service_charge_pay) + (this.state.admin_remark === "พนักงาน เปลี่ยนแปลงข้อมูล" ? this.state.total_pay + this.state.total_pay_transfer + this.state.total_pay_cradit : 0); //ค่าบริการรับส่ง
            cashier_summary.other_expenses[0] += Number(this.state.other_price); //ค่าอื่นๆ
            cashier_summary.rental_price_overdue[0] += 0; //ค่าเช่าเกินกำหนด
            cashier_summary.scale_price[0] += Number(this.state.deposit_price); //ค่าขยายสัญญา
            cashier_summary.discount += Number(this.state.discount_price); //ส่วนลด
            cashier_summary.point += Number(point * 20); //ใช้แต้ม
            cashier_summary.wallet += Number(this.state.sk_wallet); //ใช้เงินสะสม 

            cashier_summary.total_pay[0] += Number(this.state.total_pay); //ใช้เงินสะสม  
            cashier_summary.total_pay[1] += Number(this.state.total_pay_transfer); //ใช้เงินสะสม  
            cashier_summary.total_pay[2] += Number(this.state.total_pay_cradit); //ใช้เงินสะสม 
            localStorage.setItem("cashier_summary", JSON.stringify(cashier_summary));

            success_url("/admin/cashier")
        }
        this.setState({ loading: false });
    }
    Update02 = async () => {
        console.log("Update01")
        if (Number(this.state.total_pay_transfer) !== 0 && this.state.bank_name === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกบัญชี");
            return;
        }
        if (Number(this.state.total_pay_transfer) !== 0 && this.state.payment_date === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่โอน");
            return;
        }
        if (Number(this.state.total_pay_transfer) !== 0 && this.state.payment_time === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกเวลาที่โอน");
            return;
        }


        if ((Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) + (Number(this.state.point) * 20) + Number(this.state.sk_wallet) - Number(this.state.total_price) < 0) {
            alert("warning", "แจ้งเตือน", "เงินที่ชำระไม่เพียงพอ");
            return;
        }
        if (this.state.payment_picture.length === 0 && (Number(this.state.total_pay_transfer) !== 0)) {
            alert("warning", "แจ้งเตือน", "กรุณาอัพโหลดภาพหลักฐาน");
            return;
        }

        let point = 0;
        if (Number(this.state.point) % 10 !== 0) {
            point = Number(this.state.point) - Number(this.state.point) % 10;
        } else {
            point = Number(this.state.point)
        }
        this.setState({ loading: true });
        let return_point = 0;
        if (Number(this.state.rental_price) - Number(point * 20) - Number(this.state.sk_wallet) >= 300) {
            let price_point = Number(this.state.rental_price) - Number(point * 20) - Number(this.state.sk_wallet);
            price_point = price_point - (price_point % 300);
            return_point = price_point / 300;
        }
        let data = {
            rental_id: this.state.rental_id,
            rental_status: 3,
            // ชำระ/ตอนคืน
            refund_cost: 0, // ยอดเงินคืนก่อนกำหนด
            other_expenses: 0, // ค่าใช้จ่ายอื่นๆ
            fuel_cost: 0, // ค่าน้ำมันรถ
            damage_cost: 0, // ค่าความเสียหาย
            service_charge: this.state.service_charge, // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: this.state.return_date, // วันคืนรถเดิม
            return_time: this.state.return_time, // เวลาคืนรถเดิม
            return_date_new: this.state.return_date_new, // วันคืนรถใหม่
            return_time_new: this.state.return_time_new, // เวลาคืนรถใหม่
            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily", // ประเภทการเช่า
            rental_price: Number(this.state.rental_price), // ราคาเช่ารถ
            remark: this.state.remark,
            // ชำระ/ตอนเปลี่ยนรถ
            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_location: this.state.pick_up_location,
            return_location: this.state.return_location,
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            overdue_booking: 0,
            bank_name: this.state.bank_name ? this.state.bank_name.value : "",
            payment_type: this.state.payment_type,
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : format_date(new Date(), "yyyy-mm-dd", "en"),
            payment_time: this.state.payment_time ? this.state.payment_time : format_date(new Date(), "hh:mm:ss"),
            payment_picture: this.state.payment_picture,
            discount: Number(this.state.discount_price),
            return_points: return_point, // แต้มคืนให้ลูกค้า
            spend_points: Number(point), // แต้มที่ใช้
            change_points: Number(point * 20), // แต้มแลกส่วนลด
            total_price: Number(this.state.total_price),
            total_pay: Number(this.state.total_pay),
            total_pay_transfer: Number(this.state.total_pay_transfer),
            total_pay_cradit: Number(this.state.total_pay_cradit),
            change: (Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) + (Number(this.state.point) * 20) + Number(this.state.sk_wallet) - Number(this.state.total_price),
            event_type: 1,
            payment_list: [],
            return_wallet: 0,
            spend_wallet: Number(this.state.sk_wallet),

            admin_signature: "",

            agent_total_pay: 0,
            agent_payment_type: "",

            // เงินเข้า
            total_income: Number(this.state.total_price)+Number(this.state.service_charge)-Number(this.state.guarantee_amount),
            admin_remark: "แคชเชียร์ ชำระเงิน",
            admin_name: getStorage("user").full_name,

            admin_return_name: getStorage("user").full_name,
            return_remark: "",
            last_return_date: ""
        }

        let result = await POST(tokens, "v1/TH/rental-manage/payment", data);
        if (result && result.status) {
            let cashier_summary = getStorage("cashier_summary");
            cashier_summary.rental_price[0] += Number(this.state.rental_price); //ค่าเช่า
            cashier_summary.guarantee_amount[0] += Number(this.state.guarantee_amount); //ค่าค้ำประกัน
            cashier_summary.equipment_fee[0] += Number(this.state.total_price_equipment); //ค่าอุปกรณ์
            cashier_summary.delivery_price[0] += Number(this.state.delivery_price); //ค่าขนส่ง
            cashier_summary.fuel_cost[0] += 0; //ค่าน้ำมัน
            cashier_summary.damage_cost[0] += 0; //ค่าเสียหาย
            cashier_summary.service_charge[0] += 0; //ค่าบริการรับส่ง
            cashier_summary.other_expenses[0] += Number(this.state.other_price); //ค่าอื่นๆ
            cashier_summary.rental_price_overdue[0] += 0; //ค่าเช่าเกินกำหนด
            cashier_summary.scale_price[0] += 0; //ค่าขยายสัญญา
            cashier_summary.discount += Number(this.state.discount_price); //ส่วนลด
            cashier_summary.point += Number(point * 20); //ใช้แต้ม
            cashier_summary.wallet += Number(this.state.sk_wallet); //ใช้เงินสะสม 


            cashier_summary.total_pay[0] += Number(this.state.total_pay); //ใช้เงินสะสม  
            cashier_summary.total_pay[1] += Number(this.state.total_pay_transfer); //ใช้เงินสะสม  
            cashier_summary.total_pay[2] += Number(this.state.total_pay_cradit); //ใช้เงินสะสม 
            localStorage.setItem("cashier_summary", JSON.stringify(cashier_summary));

            success_url("/admin/cashier")
        }
        this.setState({ loading: false });
    }
    // ขยายสัญญา อนุมัติ ชำระ
    Scale01 = async () => {
        if (!this.state.return_date_new) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่คืนรถใหม่");
            return;
        }
        if (!this.state.return_time_new) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกเวลาคืนรถใหม่");
            return;
        }
        if (Number(this.state.total_pay_transfer) !== 0 && this.state.bank_name === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกบัญชี");
            return;
        }
        if (Number(this.state.total_pay_transfer) !== 0 && this.state.payment_date === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่โอน");
            return;
        }
        if (Number(this.state.total_pay_transfer) !== 0 && this.state.payment_time === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกเวลาที่โอน");
            return;
        }
        if (!this.state.status_pay2) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกคิดค่าขยายสัญญา");
            return;
        }



        if (this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน") {
            if (((Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) - (Number(this.state.rental_price) - (Number(this.state.point) * 20) - Number(this.state.sk_wallet) - Number(this.state.discount_pay))) < 0) {
                alert("warning", "แจ้งเตือน", "เงินที่ชำระไม่เพียงพอ");
                return;
            }
        }
        // if (Number(this.state.total_pay_transfer) !== 0 && !this.state.payment_picture && (this.state.payment_type && this.state.payment_type !== 1)) {
        //     alert("warning", "แจ้งเตือน", "กรุณาอัพโหลดภาพหลักฐาน");
        //     return;
        // }

        let point = 0;
        if (Number(this.state.point) % 10 !== 0) {
            point = Number(this.state.point) - Number(this.state.point) % 10;
        } else {
            point = Number(this.state.point)
        }
        this.setState({ loading: true });
        let data = {
            rental_id: this.state.rental_id,
            rental_status: 3,
            // ชำระ/ตอนคืน
            refund_cost: 0, // ยอดเงินคืนก่อนกำหนด
            other_expenses: 0, // ค่าใช้จ่ายอื่นๆ
            fuel_cost: 0, // ค่าน้ำมันรถ
            damage_cost: 0, // ค่าความเสียหาย
            service_charge: 0, // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: this.state.return_date, // วันคืนรถเดิม
            return_time: this.state.return_time, // เวลาคืนรถเดิม 
            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily", // ประเภทการเช่า
            rental_price: Number(this.state.rental_price), // ราคาเช่ารถ
            remark: this.state.remark,
            // ชำระ/ตอนเปลี่ยนรถ
            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_location: this.state.pick_up_location,
            return_location: this.state.return_location,
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            overdue_booking: 0,
            discount: Number(this.state.discount_pay),
            return_points: 0, // แต้มคืนให้ลูกค้า
            total_price: this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน" ? Number(this.state.rental_price) : 0,
            event_type: 2,
            payment_list: [],
            return_wallet: 0,

            admin_signature: "",

            agent_total_pay: 0,
            agent_payment_type: "",

            // เงินเข้า
            total_income: this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน" ? (Number(this.state.rental_price) - (Number(this.state.point) * 20) - Number(this.state.sk_wallet)) : 0,
            admin_remark: "แคชเชียร์ ขยายสัญญาชำระ",
            bank_name: this.state.bank_name ? this.state.bank_name.value : "",
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : format_date(new Date(), "yyyy-mm-dd", "en"),
            payment_time: this.state.payment_time ? this.state.payment_time : format_date(new Date(), "hh:mm:ss"),
            payment_type: this.state.payment_type,
            payment_picture: this.state.payment_picture,
            total_pay: this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน" ? Number(this.state.total_pay) : 0,
            total_pay_transfer: this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน" ? Number(this.state.total_pay_transfer) : 0,
            total_pay_cradit: this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน" ? Number(this.state.total_pay_cradit) : 0,
            spend_points: Number(point),
            change_points: Number(point * 20),
            change: this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน" ? ((Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) - (Number(this.state.rental_price) - (Number(this.state.point) * 20) - Number(this.state.sk_wallet))) < 0 ? 0 : (Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) - (Number(this.state.rental_price) - (Number(this.state.point) * 20) - Number(this.state.sk_wallet)) : 0,
            spend_wallet: Number(this.state.sk_wallet),
            return_date_new: this.state.return_date_new,
            return_time_new: this.state.return_time_new,
            admin_name: getStorage("user").full_name,

            admin_return_name: "",
            return_remark: "",
            last_return_date: ""
        }
        let result = await POST(tokens, "v1/TH/rental-manage/payment", data);
        if (result && result.status) {
            let cashier_summary = getStorage("cashier_summary");
            cashier_summary.rental_price[0] += 0; //ค่าเช่า
            cashier_summary.guarantee_amount[0] += 0; //ค่าค้ำประกัน
            cashier_summary.equipment_fee[0] += 0; //ค่าอุปกรณ์
            cashier_summary.delivery_price[0] += 0; //ค่าขนส่ง
            cashier_summary.fuel_cost[0] += 0; //ค่าน้ำมัน
            cashier_summary.damage_cost[0] += 0; //ค่าเสียหาย
            cashier_summary.service_charge[0] += 0; //ค่าบริการรับส่ง
            cashier_summary.other_expenses[0] += 0; //ค่าอื่นๆ
            cashier_summary.rental_price_overdue[0] += 0; //ค่าเช่าเกินกำหนด
            cashier_summary.scale_price[0] += Number(this.state.rental_price); //ค่าขยายสัญญา
            cashier_summary.discount += Number(this.state.discount_pay); //ส่วนลด
            cashier_summary.point += Number(point * 20); //ใช้แต้ม
            cashier_summary.wallet += Number(this.state.sk_wallet); //ใช้เงินสะสม 


            cashier_summary.total_pay[0] += this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน" ? Number(this.state.total_pay) : 0; //ใช้เงินสะสม  
            cashier_summary.total_pay[1] += this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน" ? Number(this.state.total_pay_transfer) : 0; //ใช้เงินสะสม  
            cashier_summary.total_pay[2] += this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน" ? Number(this.state.total_pay_cradit) : 0; //ใช้เงินสะสม 
            localStorage.setItem("cashier_summary", JSON.stringify(cashier_summary));

            success_url("/admin/list-rent")
        }
        this.setState({ loading: false });
    }
    // ขยายสัญญา อนุมัติ ไม่ชำระ
    Scale02 = async () => {
        if (!this.state.return_date_new) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่คืนรถใหม่");
            return;
        }
        if (!this.state.return_time_new) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกเวลาคืนรถใหม่");
            return;
        }
        this.setState({ loading: true });
        let data = {
            rental_id: this.state.rental_id,
            rental_status: 7,
            // ชำระ/ตอนคืน
            refund_cost: 0, // ยอดเงินคืนก่อนกำหนด
            other_expenses: 0, // ค่าใช้จ่ายอื่นๆ
            fuel_cost: 0, // ค่าน้ำมันรถ
            damage_cost: 0, // ค่าความเสียหาย
            service_charge: 0, // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: this.state.return_date, // วันคืนรถเดิม
            return_time: this.state.return_time, // เวลาคืนรถเดิม 
            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily", // ประเภทการเช่า
            rental_price: Number(this.state.rental_price), // ราคาเช่ารถ
            remark: this.state.remark,
            // ชำระ/ตอนเปลี่ยนรถ
            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_location: this.state.pick_up_location,
            return_location: this.state.return_location,
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            overdue_booking: 0,
            discount: Number(this.state.discount_pay),
            return_points: 0, // แต้มคืนให้ลูกค้า
            total_price: Number(this.state.rental_price) - Number(this.state.discount_pay),
            event_type: 2,
            payment_list: [],
            return_wallet: 0,

            admin_signature: "",

            agent_total_pay: 0,
            agent_payment_type: "",

            total_income: 0,
            admin_remark: "แคชเชียร์ ขยายสัญญาไม่ชำระ",
            bank_name: "",
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : format_date(new Date(), "yyyy-mm-dd", "en"),
            payment_time: this.state.payment_time ? this.state.payment_time : format_date(new Date(), "hh:mm:ss"),
            payment_type: 0,
            payment_picture: [],
            total_pay: 0,
            total_pay_transfer: 0,
            total_pay_cradit: 0,
            spend_points: 0,
            change_points: 0,
            change: 0,
            spend_wallet: 0,
            return_date_new: this.state.return_date_new,
            return_time_new: this.state.return_time_new,
            admin_name: getStorage("user").full_name,

            admin_return_name: "",
            return_remark: "",
            last_return_date: ""
        }
        let result = await POST(tokens, "v1/TH/rental-manage/payment", data);
        if (result && result.status) {
            success_url("/admin/list-rent")
        }
        this.setState({ loading: false });
    }
    // ขยายสัญญา ไม่อนุมัติ
    Scale03 = async () => {
        let point = 0;
        if (Number(this.state.point) % 10 !== 0) {
            point = Number(this.state.point) - Number(this.state.point) % 10;
        } else {
            point = Number(this.state.point)
        }
        this.setState({ loading: true });
        let data = {
            rental_id: this.state.rental_id,
            rental_status: 3,
            // ชำระ/ตอนคืน
            refund_cost: 0, // ยอดเงินคืนก่อนกำหนด
            other_expenses: 0, // ค่าใช้จ่ายอื่นๆ
            fuel_cost: 0, // ค่าน้ำมันรถ
            damage_cost: 0, // ค่าความเสียหาย
            service_charge: 0, // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: this.state.return_date, // วันคืนรถเดิม
            return_time: this.state.return_time, // เวลาคืนรถเดิม 
            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily", // ประเภทการเช่า
            rental_price: 0, // ราคาเช่ารถ
            remark: this.state.remark,
            // ชำระ/ตอนเปลี่ยนรถ
            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_location: this.state.pick_up_location,
            return_location: this.state.return_location,
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            overdue_booking: 0,
            discount: 0,
            return_points: 0, // แต้มคืนให้ลูกค้า
            total_price: 0,
            event_type: 2,
            payment_list: [],
            return_wallet: 0,

            admin_signature: "",

            agent_total_pay: 0,
            agent_payment_type: "",

            total_income: 0,
            admin_remark: "แคชเชียร์ ขยายสัญญาไม่อนุมัติ",
            bank_name: "",
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : format_date(new Date(), "yyyy-mm-dd", "en"),
            payment_time: this.state.payment_time ? this.state.payment_time : format_date(new Date(), "hh:mm:ss"),
            payment_type: 0,
            payment_picture: [],
            total_pay: 0,
            total_pay_transfer: 0,
            total_pay_cradit: 0,
            spend_points: 0,
            change_points: 0,
            change: 0,
            spend_wallet: 0,
            return_date_new: null,
            return_time_new: null,
            admin_name: getStorage("user").full_name,

            admin_return_name: "",
            return_remark: "",
            last_return_date: ""
        }
        let result = await POST(tokens, "v1/TH/rental-manage/payment", data);
        if (result && result.status) {
            success_url("/admin/list-rent")
        }
        this.setState({ loading: false });
    }
    // ขยายสัญญา รอเงินมัดจำ
    Scale04 = async () => {
        let point = 0;
        if (Number(this.state.point) % 10 !== 0) {
            point = Number(this.state.point) - Number(this.state.point) % 10;
        } else {
            point = Number(this.state.point)
        }
        this.setState({ loading: true });
        let data = {
            rental_id: this.state.rental_id,
            rental_status: 12,
            // ชำระ/ตอนคืน
            refund_cost: 0, // ยอดเงินคืนก่อนกำหนด
            other_expenses: 0, // ค่าใช้จ่ายอื่นๆ
            fuel_cost: 0, // ค่าน้ำมันรถ
            damage_cost: 0, // ค่าความเสียหาย
            service_charge: 0, // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: this.state.return_date, // วันคืนรถเดิม
            return_time: this.state.return_time, // เวลาคืนรถเดิม 
            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily", // ประเภทการเช่า
            rental_price: Number(this.state.rental_price), // ราคาเช่ารถ
            remark: this.state.remark,
            // ชำระ/ตอนเปลี่ยนรถ
            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_location: this.state.pick_up_location,
            return_location: this.state.return_location,
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            overdue_booking: 0,
            discount: Number(this.state.discount_pay),
            return_points: 0, // แต้มคืนให้ลูกค้า
            total_price: Number(this.state.rental_price),
            event_type: 2,
            payment_list: [],
            return_wallet: 0,

            admin_signature: "",

            agent_total_pay: 0,
            agent_payment_type: "",

            total_income: 0,
            admin_remark: "แคชเชียร์ ขยายสัญญารอเงินมัดจำ",
            bank_name: this.state.bank_name ? this.state.bank_name.value : "",
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : format_date(new Date(), "yyyy-mm-dd", "en"),
            payment_time: this.state.payment_time ? this.state.payment_time : format_date(new Date(), "hh:mm:ss"),
            payment_type: this.state.payment_type,
            payment_picture: this.state.payment_picture,
            total_pay: Number(this.state.total_pay),
            total_pay_transfer: Number(this.state.total_pay_transfer),
            total_pay_cradit: Number(this.state.total_pay_cradit),
            spend_points: Number(point),
            change_points: Number(point * 20),
            change: ((Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) - (Number(this.state.rental_price) - (Number(this.state.point) * 20) - Number(this.state.sk_wallet))) < 0 ? 0 : (Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) - (Number(this.state.rental_price) - (Number(this.state.point) * 20) - Number(this.state.sk_wallet)),
            spend_wallet: Number(this.state.sk_wallet),
            return_date_new: this.state.return_date_new,
            return_time_new: this.state.return_time_new,
            admin_name: getStorage("user").full_name,

            admin_return_name: "",
            return_remark: "",
            last_return_date: ""
        }
        let result = await POST(tokens, "v1/TH/rental-manage/payment", data);
        if (result && result.status) {
            success_url("/admin/list-rent")
        }
        this.setState({ loading: false });
    }
    ReturnCar10 = async () => {
        if (Number(this.state.total_pay_transfer) !== 0 && this.state.bank_name === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกบัญชี");
            return;
        }
        if (Number(this.state.total_pay_transfer) !== 0 && this.state.payment_date === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่โอน");
            return;
        }
        if (Number(this.state.total_pay_transfer) !== 0 && this.state.payment_time === "") {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกเวลาที่โอน");
            return;
        }
        if ((
            (Number(this.state.total_pay) + Number(this.state.total_pay_transfer) + Number(this.state.total_pay_cradit)) - (
                Number(this.state.fuel_cost) +
                Number(this.state.damage_cost) +
                Number(this.state.service_charge) +
                Number(this.state.service_charge_overdue) +
                Number(this.state.other_expenses) -
                Number(this.state.discount_pay) -
                Number(this.state.guarantee_amount) +
                (this.state.data_detail ? total(this.state.data_detail.filter(item => item.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || item.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0) -
                (Number(this.state.point) * 20) -
                Number(this.state.sk_wallet)
            )
        ) < 0) {
            alert("warning", "แจ้งเตือน", "เงินที่ชำระไม่เพียงพอ");
            return;
        }
        // if (!this.state.payment_picture && (Number(this.state.total_pay_transfer) !== 0)) {
        //     alert("warning", "แจ้งเตือน", "กรุณาอัพโหลดภาพหลักฐาน");
        //     return;
        // }
        if (this.state.agent_id && !this.state.status_bill_agent) {
            if (!this.state.agent_payment_type) {
                alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทการจ่ายค่าแนะนำ");
                return;
            }
            if (!this.state.admin_signature) {
                alert("warning", "แจ้งเตือน", "กรุณาเซ็นต์ลายเซ็นต์ผู้รับเงิน");
                return;
            }
        }
        this.setState({ loading: true });
        let return_point = 0;
        let pay = (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ" || e.admin_remark === "พนักงาน ขยายสัญญาชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาชำระ"), "total_price") : 0);
        if (pay >= 300) {
            pay = pay - (pay % 300);
            return_point = pay / 300;
        }
        let point = 0;
        if (Number(this.state.point) % 10 !== 0) {
            point = Number(this.state.point) - Number(this.state.point) % 10;
        } else {
            point = Number(this.state.point)
        }
        let data = {
            rental_id: this.state.rental_id,
            rental_status: 10,
            // ชำระ/ตอนคืน
            refund_cost: Number(this.state.refund_cost), // ยอดเงินคืนก่อนกำหนด
            other_expenses: Number(this.state.other_expenses), // ค่าใช้จ่ายอื่นๆ
            fuel_cost: Number(this.state.fuel_cost), // ค่าน้ำมันรถ
            damage_cost: Number(this.state.damage_cost), // ค่าความเสียหาย
            service_charge: Number(this.state.service_charge) + Number(this.state.service_charge_overdue) + Number(this.state.service_charge_pay), // ค่าบริการรับส่ง
            rental_price_overdue: Number(this.state.rental_price_overdue),//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: this.state.return_date, // วันคืนรถเดิม
            return_time: this.state.return_time, // เวลาคืนรถเดิม
            return_date_new: null, // วันคืนรถใหม่
            return_time_new: null, // เวลาคืนรถใหม่
            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily", // ประเภทการเช่า
            rental_price: Number(this.state.rental_price), // ราคาเช่ารถ
            remark: this.state.remark,
            // ชำระ/ตอนเปลี่ยนรถ
            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_location: this.state.pick_up_location,
            return_location: this.state.return_location,
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            overdue_booking: 0,
            bank_name: "",
            payment_type: this.state.payment_type,
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : format_date(new Date(), "yyyy-mm-dd", "en"),
            payment_time: this.state.payment_time ? this.state.payment_time : format_date(new Date(), "hh:mm:ss"),
            payment_picture: this.state.payment_picture,
            discount: Number(this.state.discount_pay),
            return_points: return_point, // แต้มคืนให้ลูกค้า
            spend_points: Number(point), // แต้มที่ใช้
            change_points: Number(point) * 20, // แต้มแลกส่วนลด
            total_price: (Number(this.state.fuel_cost) +
                Number(this.state.damage_cost) +
                Number(this.state.service_charge) +
                Number(this.state.service_charge_overdue) +
                Number(this.state.other_expenses) -
                Number(this.state.discount_pay) +
                Number(this.state.rental_price_overdue) +
                (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
            ) < 0 ? 0 :
                (
                    Number(this.state.fuel_cost) +
                    Number(this.state.damage_cost) +
                    Number(this.state.service_charge) +
                    Number(this.state.service_charge_overdue) +
                    Number(this.state.other_expenses) -
                    Number(this.state.discount_pay) +
                    Number(this.state.rental_price_overdue) +
                    (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
                ),
            total_pay: Number(this.state.total_pay),
            total_pay_transfer: Number(this.state.total_pay_transfer),
            total_pay_cradit: Number(this.state.total_pay_cradit),
            change: 0,
            event_type: 3,
            payment_list: [],
            return_wallet: Number(this.state.refund_cost),
            spend_wallet: Number(this.state.sk_wallet),

            admin_signature: this.state.admin_signature,

            agent_total_pay: !this.state.status_bill_agent ? Number(this.state.agent_total_pay) : 0,
            agent_payment_type: !this.state.status_bill_agent ? this.state.agent_payment_type ? Number(this.state.agent_payment_type.value) : 0 : 0,

            total_income: (Number(this.state.fuel_cost) +
                Number(this.state.damage_cost) + 
                Number(this.state.service_charge) +
                Number(this.state.service_charge_overdue) +
                Number(this.state.other_expenses) -
                Number(this.state.discount_pay) +
                Number(this.state.rental_price_overdue) +
                (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
            ) < 0 ? 0 :
                (
                    Number(this.state.fuel_cost) +
                    Number(this.state.damage_cost) +
                    Number(this.state.service_charge) +
                    Number(this.state.service_charge_overdue) +
                    Number(this.state.other_expenses) -
                    Number(this.state.discount_pay) +
                    Number(this.state.rental_price_overdue) +
                    (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
                ),
            admin_remark: "แคชเชียร์ ยืนยันการคืนรถ",
            admin_name: getStorage("user").full_name,

            admin_return_name: this.state.rental_status === 6 ? this.state.admin_return_name : getStorage("user").full_name,
            return_remark: this.state.return_remark,
            last_return_date: this.state.rental_status !== 6 ? format_date(new Date(), "yyyy-mm-dd hh:mm:ss", "en") : ""
        } 
        let result = await POST(tokens, "v1/TH/rental-manage/payment", data);
        if (result && result.status) {
            let cashier_summary = getStorage("cashier_summary");
            let agent_type = 0;
            if (this.state.agent_payment_type) {
                switch (Number(this.state.agent_payment_type.value)) {
                    case 1: agent_type = 0; break;
                    case 2: agent_type = 1; break;
                    default: break;
                }
            }
            cashier_summary.rental_price[0] += 0; //ค่าเช่า
            cashier_summary.guarantee_amount[0] += 0; //ค่าค้ำประกัน
            cashier_summary.return_guarantee_amount += Number(this.state.guarantee_amount); //คืนค่าค้ำประกัน
            cashier_summary.equipment_fee[0] += 0; //ค่าอุปกรณ์
            cashier_summary.delivery_price[0] += 0; //ค่าขนส่ง
            cashier_summary.fuel_cost[0] += Number(this.state.fuel_cost); //ค่าน้ำมัน
            cashier_summary.damage_cost[0] += Number(this.state.damage_cost); //ค่าเสียหาย
            cashier_summary.service_charge[0] += Number(this.state.service_charge) + Number(this.state.service_charge_overdue); //ค่าบริการรับส่ง
            cashier_summary.other_expenses[0] += Number(this.state.other_expenses); //ค่าอื่นๆ
            cashier_summary.rental_price_overdue[0] += Number(this.state.rental_price_overdue); //ค่าเช่าเกินกำหนด
            cashier_summary.scale_price[0] += (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0); //ค่าขยายสัญญา
            cashier_summary.discount += Number(this.state.discount_pay); //ส่วนลด
            cashier_summary.point += Number(point * 20); //ใช้แต้ม
            cashier_summary.wallet += Number(this.state.sk_wallet); //ใช้เงินสะสม
            cashier_summary.agent[agent_type] += !this.state.status_bill_agent ? Number(this.state.agent_total_pay) : 0; //ค่าแนะนำ

            cashier_summary.total_pay[0] += Number(this.state.total_pay); //ใช้เงินสะสม  
            cashier_summary.total_pay[1] += Number(this.state.total_pay_transfer); //ใช้เงินสะสม  
            cashier_summary.total_pay[2] += Number(this.state.total_pay_cradit); //ใช้เงินสะสม 
            localStorage.setItem("cashier_summary", JSON.stringify(cashier_summary));

            success_url("/admin/cashier")
        }
        this.setState({ loading: false });
    }
    Agent = async () => {
        if (!this.state.agent_payment_type) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทการจ่ายค่าแนะนำ");
            return;
        }
        if (!this.state.admin_signature) {
            alert("warning", "แจ้งเตือน", "กรุณาเซ็นต์ลายเซ็นต์ผู้รับเงิน");
            return;
        }
        this.setState({ loading: true });

        let data = {
            rental_id: this.state.rental_id,
            rental_status: 0,
            // ชำระ/ตอนคืน
            refund_cost: 0, // ยอดเงินคืนก่อนกำหนด
            other_expenses: 0, // ค่าใช้จ่ายอื่นๆ
            fuel_cost: 0, // ค่าน้ำมันรถ
            damage_cost: 0, // ค่าความเสียหาย
            service_charge: 0, // ค่าบริการรับส่ง
            rental_price_overdue: 0,//ค่าเช่าเกินกำหนด 
            // ชำระ/ตอนขยายสัญญา
            return_date: this.state.return_date, // วันคืนรถเดิม
            return_time: this.state.return_time, // เวลาคืนรถเดิม
            return_date_new: null, // วันคืนรถใหม่
            return_time_new: null, // เวลาคืนรถใหม่
            rental_type: this.state.rental_type ? this.state.rental_type.value : "daily", // ประเภทการเช่า
            rental_price: 0, // ราคาเช่ารถ
            remark: "",
            // ชำระ/ตอนเปลี่ยนรถ
            brand_name: this.state.selected_bike.brand_name,
            model_name: this.state.selected_bike.model_name,
            license_plate: this.state.selected_bike.license_plate,
            chassis_number: this.state.selected_bike.chassis_number,
            bike_color: this.state.selected_bike.bike_color,
            cc_size: this.state.selected_bike.cc_size,
            bike_year: this.state.selected_bike.bike_year,
            title_picture: this.state.selected_bike.title_picture,
            pick_up_location: this.state.pick_up_location,
            return_location: this.state.return_location,
            // ตัวแปรร่วม (ชำระ/ตอนเช่า)
            overdue_booking: 0,
            bank_name: "",
            payment_type: this.state.payment_type,
            payment_date: this.state.payment_date ? format_date(this.state.payment_date, "yyyy-mm-dd", "en") : format_date(new Date(), "yyyy-mm-dd", "en"),
            payment_time: this.state.payment_time ? this.state.payment_time : format_date(new Date(), "hh:mm:ss"),
            payment_picture: [],
            discount: 0,
            return_points: 0, // แต้มคืนให้ลูกค้า
            spend_points: 0, // แต้มที่ใช้
            change_points: 0, // แต้มแลกส่วนลด
            total_price: 0,
            total_pay: 0,
            total_pay_transfer: 0,
            total_pay_cradit: 0,
            change: 0,
            event_type: 5,
            payment_list: [],
            return_wallet: 0,
            spend_wallet: 0,

            admin_signature: this.state.admin_signature,

            agent_total_pay: Number(this.state.agent_total_pay),
            agent_payment_type: this.state.agent_payment_type ? Number(this.state.agent_payment_type.value) : "",

            total_income: 0,
            admin_remark: "แคชเชียร์ เบิกค่าแนะนำ",
            admin_name: getStorage("user").full_name,
            admin_return_name: "",
            return_remark: "",
            last_return_date: ""
        }
        let result = await POST(tokens, "v1/TH/rental-manage/payment", data);
        if (result && result.status) {
            let cashier_summary = getStorage("cashier_summary");
            let agent_type = 0;
            if (this.state.agent_payment_type) {
                switch (Number(this.state.agent_payment_type.value)) {
                    case 1: agent_type = 0; break;
                    case 2: agent_type = 1; break;
                    default: break;
                }
            }
            cashier_summary.agent[agent_type] += Number(this.state.agent_total_pay); //ค่าแนะนำ
            localStorage.setItem("cashier_summary", JSON.stringify(cashier_summary));

            success_url("/admin/cashier")
        }
        this.setState({ loading: false });
    }
    trim = () => {
        this.setState({ admin_signature: this.sigPad.getTrimmedCanvas().toDataURL('image/png').split(",")[1] })
    }
    import_file2 = (e, type) => { 
        if (e.target.files.length === 1) {
            this.changePhoto2(e.target.files[0], type)
            document.getElementById("file_sub2").value = "";
            document.getElementById("file_sub2").file = null;
        } else {
            this.multiple_file2(0, e.target.files.length, e.target.files);
        }
    }
    multiple_file2 = async (index, max, file) => {
        await this.changePhoto2(file[index], "sub2");
        if (index + 1 < max) {
            setTimeout(() => {
                this.multiple_file2(index + 1, max, file);
            }, 50);
        }
    }
    changePhoto2 = async (file, type) => {
        if (!file.type.includes("image")) {
            alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
            return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => { }
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file, 1024, 1024, "JPG", 100, 0,
                (uri) => {
                    let payment_picture = this.state.payment_picture;
                    payment_picture.push(
                        {
                            picture_id: "",
                            picture: uri.split(",")[1]
                        }
                    )
                    this.setState({
                        payment_picture: payment_picture
                    })
                },
                "base64"
            );
        });
    }
    removeSave = async () => {
        await GET(tokens, "v1/TH/data-active/clear-active/" + this.state.rental_number, null);
        window.location.href = "/admin/cashier"
    }
    render() {
        return (
            <div className="w-100 min-vh-100 fade-in">
                {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)}
                <Navbar page1="หน้าหลัก" page2="แคชเชียร์" />
                {/* BODY */}
                <div className="body bg-light min-vh-100 overflow-scroll">
                    <div className="p-3">
                        {/* หัวข้อ และ ปุ่มเพิ่ม */}
                        <div className="d-flex row mx-0 align-items-center justify-content-between test">
                            <h4 className="mb-3"><b>แคชเชียร์ : {this.state.rental_number}</b>
                                {status_rent(this.state.rental_status, this.state.event_type)}
                            </h4>
                            <button className="btn btn-outline-orange px-4 mx-1 mb-3" onClick={() => { this.removeSave(); }}>
                                <label className="icon mr-2" > {"\uf060"} </label>
                                ย้อนกลับ
                            </button>
                        </div>
                        <div className="row">
                            {/* ซ้าย */}
                            <div className="col-12 col-lg-7 mb-3">
                                <div className="card border-0 overflow-hidden mb-3">
                                    <div className="card-body p-0">
                                        <div className="p-3">
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <div className="w-100 mb-3">
                                                        <label className="w-100 mb-3">ชื่อผู้เช่า :</label><br />
                                                        <input
                                                            className="form-control  validate"
                                                            type="text"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                this.setState({ full_name: e.target.value })
                                                            }}
                                                            value={this.state.full_name}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="w-100 mb-3">
                                                        <label className="w-100 mb-3">ชื่อพนักงาน :</label><br />
                                                        <input
                                                            className="form-control  validate"
                                                            type="text"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                this.setState({ admin_name: e.target.value })
                                                            }}
                                                            value={this.state.admin_name}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="card bg-dark w-100 p-2">
                                                        <div className="row">
                                                            <div className="col-12 col-md-6 text-center">
                                                                <label className="text-white">แต้มสะสม</label>
                                                                <div className="d-flex align-items-end justify-content-center">
                                                                    <h3 className="text-white mb-0"><b>{this.state.wallet && this.state.wallet.point ? toFixed(this.state.wallet.point) : "0"}</b></h3>
                                                                    <label className="text-white ml-3">แต้ม</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 text-center">
                                                                <label className="text-white">เงินมัดจำคงเหลือ</label>
                                                                <div className="d-flex align-items-end justify-content-center">
                                                                    <h3 className="text-white mb-0"><b>{this.state.wallet && this.state.wallet.sk_wallet ? toFixed(this.state.wallet.sk_wallet) : "0.00"}</b></h3>
                                                                    <label className="text-white ml-3">บาท</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* รอยืนยันการชำระ */}
                                {this.state.rental_status === 0 && (
                                    <div className="card border-0 overflow-hidden">
                                        <div className="card-body p-0">
                                            {/* ตาราง */}
                                            <div className="table-responsive mb-3">
                                                <table className="table table-borderless table-striped">
                                                    <thead className="bg-orange text-white">
                                                        <tr>
                                                            <th className="text-center">รายการ</th>
                                                            <th className="text-center">วันที่ชำระ</th>
                                                            <th className="text-center">สถานะ</th>
                                                            <th className="text-center">จำนวนเงิน</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-left">ค่าเช่ารถ</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                            <td className="text-center">{toFixed(this.state.rental_price)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">เงินค้ำประกัน</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                            <td className="text-center">{toFixed(this.state.guarantee_amount)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">ค่าอุปกรณ์</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                            <td className="text-center">{toFixed(this.state.total_price_equipment)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">ค่าขนส่ง</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                            <td className="text-center">{toFixed(this.state.delivery_price)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">ค่าอื่น ๆ</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                            <td className="text-center">{toFixed(this.state.other_price)}</td>
                                                        </tr>
                                                        {this.state.total_pay_booking !== 0 && (
                                                            <tr>
                                                                <td className="text-left">ยอดจองชำระแล้ว</td>
                                                                <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                <td className="text-center">{toFixed(this.state.total_pay_booking)}</td>
                                                            </tr>
                                                        )}
                                                        {this.state.deposit_price ? (
                                                            <tr>
                                                                <td className="text-left">ค่าขยายสัญญา {this.state.scale_days ? " ( " + this.state.scale_days + " วัน )" : ""}</td>
                                                                <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                <td className="text-center">{toFixed(this.state.deposit_price)}</td>
                                                            </tr>
                                                        ) : null}
                                                        {this.state.service_charge ? (
                                                            <tr>
                                                                <td className="text-left">ค่าบริการรับส่ง</td>
                                                                <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                <td className="text-center">{toFixed(this.state.service_charge)}</td>
                                                            </tr>
                                                        ) : null}
                                                        <tr>
                                                            <td className="text-left">ส่วนลด</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(this.state.discount_price)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* รอยืนยันการชำระ */}
                                {this.state.rental_status === 1 && (
                                    <div className="card border-0 overflow-hidden">
                                        <div className="card-body p-0">
                                            {/* ตาราง */}
                                            <div className="table-responsive mb-3">
                                                <table className="table table-borderless table-striped">
                                                    <thead className="bg-orange text-white">
                                                        <tr>
                                                            <th className="text-center">รายการ</th>
                                                            <th className="text-center">วันที่ชำระ</th>
                                                            <th className="text-center">สถานะ</th>
                                                            <th className="text-center">จำนวนเงิน</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-left">ค่าเช่ารถ</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center">{this.state.rental_price_pay || this.state.rental_price === 0 ? (<span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span>) : (<span className="bg-warning rounded px-2 py-1 text-white">ยังไม่ได้ชำระ</span>)}</td>
                                                            <td className="text-center">{toFixed(this.state.rental_price)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">เงินค้ำประกัน</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                            <td className="text-center">{toFixed(this.state.guarantee_amount)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">ค่าอุปกรณ์</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                            <td className="text-center">{toFixed(this.state.total_price_equipment)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">ค่าขนส่ง</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                            <td className="text-center">{toFixed(this.state.delivery_price)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">ค่าอื่น ๆ</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                            <td className="text-center">{toFixed(this.state.other_price)}</td>
                                                        </tr>
                                                        {this.state.total_pay_booking !== 0 && (
                                                            <tr>
                                                                <td className="text-left">ยอดจองชำระแล้ว</td>
                                                                <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                <td className="text-center">{toFixed(this.state.total_pay_booking)}</td>
                                                            </tr>
                                                        )}
                                                        {this.state.deposit_price ? (
                                                            <tr>
                                                                <td className="text-left">ค่าขยายสัญญา {this.state.scale_days ? " ( " + this.state.scale_days + " วัน )" : ""}</td>
                                                                <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                <td className="text-center">{toFixed(this.state.deposit_price)}</td>
                                                            </tr>
                                                        ) : null}
                                                        {this.state.service_charge ? (
                                                            <tr>
                                                                <td className="text-left">ค่าบริการรับส่ง</td>
                                                                <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                <td className="text-center">{this.state.rental_price_pay || this.state.service_charge === 0 ? (<span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span>) : (<span className="bg-warning rounded px-2 py-1 text-white">ยังไม่ได้ชำระ</span>)}</td>
                                                                <td className="text-center">{toFixed(this.state.service_charge)}</td>
                                                            </tr>
                                                        ) : null}
                                                        <tr>
                                                            <td className="text-left">ส่วนลด</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(this.state.discount_price)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* รอชำระ */}
                                {this.state.rental_status === 2 && (
                                    <div className="card border-0 overflow-hidden">
                                        <div className="card-body p-0">
                                            {/* ตาราง */}
                                            <div className="table-responsive">
                                                <table className="table table-borderless table-striped">
                                                    <thead className="bg-orange text-white">
                                                        <tr>
                                                            <th className="text-center">รายการ</th>
                                                            <th className="text-center">วันที่ชำระ</th>
                                                            <th className="text-center">สถานะ</th>
                                                            <th className="text-center">จำนวนเงิน</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-left">ค่าเช่ารถ</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-warning rounded px-2 py-1 text-white">ยังไม่ได้ชำระ</span></td>
                                                            <td className="text-center">{toFixed(this.state.rental_price)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">เงินค้ำประกัน</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-warning rounded px-2 py-1 text-white">ยังไม่ได้ชำระ</span></td>
                                                            <td className="text-center">{toFixed(this.state.guarantee_amount)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">ค่าอุปกรณ์</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-warning rounded px-2 py-1 text-white">ยังไม่ได้ชำระ</span></td>
                                                            <td className="text-center">{toFixed(this.state.total_price_equipment)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">ค่าขนส่ง</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-warning rounded px-2 py-1 text-white">ยังไม่ได้ชำระ</span></td>
                                                            <td className="text-center">{toFixed(this.state.delivery_price)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">ค่าอื่น ๆ</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-warning rounded px-2 py-1 text-white">ยังไม่ได้ชำระ</span></td>
                                                            <td className="text-center">{toFixed(this.state.other_price)}</td>
                                                        </tr>
                                                        {this.state.total_pay_booking !== 0 && (
                                                            <tr>
                                                                <td className="text-left">ยอดจองชำระแล้ว</td>
                                                                <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                                <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                                <td className="text-center">{toFixed(this.state.total_pay_booking)}</td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <td className="text-left">ส่วนลด</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(this.state.discount_price)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* รอยืนยันการชำระ (คืนรถ) */}
                                {this.state.rental_status === 6 && (
                                    <div className="card border-0 overflow-hidden">
                                        <div className="card-body p-0">
                                            {/* ตาราง */}
                                            <div className="table-responsive mb-3">
                                                <table className="table table-borderless table-striped">
                                                    <thead className="bg-orange text-white">
                                                        <tr>
                                                            <th className="text-center">รายการ</th>
                                                            <th className="text-center">วันที่ชำระ</th>
                                                            <th className="text-center">สถานะ</th>
                                                            <th className="text-center">จำนวนเงิน</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-left">ค่าเช่ารถ</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                            <td className="text-center">{toFixed(this.state.rental_price)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">เงินค้ำประกัน</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                            <td className="text-center">{toFixed(this.state.guarantee_amount)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">ค่าเช่าเกินกำหนด</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                            <td className="text-center">{toFixed(this.state.rental_price_overdue)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">ค่าน้ำมัน</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                            <td className="text-center">{toFixed(this.state.fuel_cost)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">ค่าเสียหาย</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                            <td className="text-center">{toFixed(this.state.damage_cost)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">ค่าบริการรับส่ง</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                            <td className="text-center">{toFixed(this.state.service_charge)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">ค่าอื่น ๆ</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                            <td className="text-center">{toFixed(this.state.other_expenses)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">ค่ามัดจำ</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"><span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span></td>
                                                            <td className="text-center">{this.state.data_detail ? toFixed(total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price")) : "0.00"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left">ส่วนลด</td>
                                                            <td className="text-center">{this.state.datetime_update ? format_date(this.state.datetime_update, "dd/mm/yyyy hh:mm:ss") : ""}</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-center">{toFixed(this.state.discount_pay)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            {/* หมายเหตุ */}
                                            <div className="w-100 mb-2">
                                                <label className="w-100 mb-2">หมายเหตุ :</label><br />
                                                <textarea
                                                    className="form-control validate"
                                                    placeholder="กรอกข้อมูล"
                                                    rows={5}
                                                    value={this.state.remark}
                                                    onChange={(e) => { this.setState({ remark: e.target.value }) }}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.rental_status === 9 && (
                                    <div className="card border-0">
                                        <div className="card-body p-3">
                                            <div className="row">
                                                {/* ข้อมูลรถ */}
                                                <div className="col-12">
                                                    <div className="d-flex justify-content-between">
                                                        <h4><b>ข้อมูลรถ</b></h4>
                                                    </div>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-12 col-md-5 col-lg-12 col-xl-5 mb-3 text-center">
                                                            <img src={this.state.selected_bike ? this.state.selected_bike.title_picture ? this.state.selected_bike.title_picture : CAR : CAR} className="img-fluid w-100 rounded" style={{ maxHeight: 300, objectFit: "contain" }} />
                                                        </div>
                                                        <div className="col-12 col-md-7 col-lg-12 col-xl-7 mb-3">
                                                            <h6><b>รายละเอียดรถ</b></h6>
                                                            <div className="row">
                                                                <div className="col-12 d-flex mb-2">
                                                                    <b className="wpx-50">ยี่ห้อ</b><label>: {this.state.selected_bike ? this.state.selected_bike.brand_name : "-"}</label>
                                                                </div>
                                                                <div className="col-6 d-flex mb-2">
                                                                    <b className="wpx-50">ชื่อรุ่น</b><label>: {this.state.selected_bike ? this.state.selected_bike.model_name : "-"}</label>
                                                                </div>
                                                                <div className="col-6 d-flex mb-2">
                                                                    <b className="wpx-50">ทะเบียน</b><label>: {this.state.selected_bike ? this.state.selected_bike.license_plate : "-"}</label>
                                                                </div>
                                                                <div className="col-6 d-flex mb-2">
                                                                    <b className="wpx-50">สี</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_color : "-"}</label>
                                                                </div>
                                                                <div className="col-6 d-flex mb-2">
                                                                    <b className="wpx-50">ปี</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_year + 543 : "-"}</label>
                                                                </div>
                                                                <div className="col-6 d-flex mb-2">
                                                                    <b className="wpx-100">หมายเลขรถ</b><label>: {this.state.selected_bike ? this.state.selected_bike.channel_name : "-"}</label>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <h6><b>วันที่รับ / คืนรถ</b></h6>
                                                            <div className="d-flex justify-content-between">
                                                                <div className="w-100">
                                                                    <b className="wpx-40">วันที่รับรถ</b>
                                                                    <label className="ml-2">{this.state.pick_up_date ? format_date(this.state.pick_up_date, "dd mmm yyyy") : "-"} {this.state.pick_up_time ? this.state.pick_up_time : "-"}</label><br />
                                                                    <b className="my-2">สถานที่รับรถ</b>
                                                                    <input
                                                                        className="form-control validate"
                                                                        type="text"
                                                                        placeholder="กรอกข้อมูล"
                                                                        value={this.state.pick_up_location}
                                                                        onChange={(e) => { this.setState({ pick_up_location: e.target.value }) }}
                                                                    />
                                                                </div>
                                                                <div className="wpx-60 d-flex justify-content-center align-items-center">
                                                                    <label className="icon text-24 text-orange">{"\uf356"}</label>
                                                                </div>
                                                                <div className="w-100">
                                                                    <b className="wpx-40">วันที่คืนรถ</b>
                                                                    <label className="ml-2">{this.state.return_date ? format_date(this.state.return_date, "dd mmm yyyy") : "-"} {this.state.return_time ? this.state.return_time : "-"}</label><br />
                                                                    <b className="my-2">สถานที่คืนรถ</b>
                                                                    <input
                                                                        className="form-control validate"
                                                                        type="text"
                                                                        placeholder="กรอกข้อมูล"
                                                                        value={this.state.return_location}
                                                                        onChange={(e) => { this.setState({ return_location: e.target.value }) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 d-flex justify-content-end mt-3 mb-2">
                                                                <h4 className="text-orange">{this.state.selected_bike ? this.state.selected_bike.daily_price : "-"}  บาท/วัน</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0">
                                                        <div className="col-12 px-1">
                                                            <div className="card bg-orange w-100 p-2">
                                                                <div className="row">
                                                                    <div className="col-3 text-center">
                                                                        <label className="text-white">จำนวน (วัน)</label>
                                                                        <h3 className="text-white"><b>{this.state.days}</b></h3>
                                                                    </div>
                                                                    <div className="col-3 text-center">
                                                                        <label className="text-white">ราคารถเก่า</label>
                                                                        <h3 className="text-white"><b>{toFixed(this.state.old_price)}</b></h3>
                                                                    </div>
                                                                    <div className="col-3 text-center">
                                                                        <label className="text-white">ราคารถใหม่</label>
                                                                        <h3 className="text-white"><b>{toFixed(this.state.rental_price)}</b></h3>
                                                                    </div>
                                                                    <div className="col-3 text-center">
                                                                        <label className="text-white">ราคาส่วนต่าง</label>
                                                                        <div className="w-100 card bg-white text-center">
                                                                            <h3 className="text-orange"><b>{toFixed(this.state.rental_price - this.state.old_price)}</b></h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ฟอร์มชำระเงิน */}
                                                <div className="col-12 pt-3">
                                                    <div className="row mx-0">
                                                        <div className="col-12 col-sm-6 col-md-4 px-1">
                                                            {/* ค่าบริการรับส่ง */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-1">ค่าบริการรับส่ง :</label><br />
                                                                <input
                                                                    className="form-control validate"
                                                                    type="text"
                                                                    placeholder="กรอกข้อมูล"
                                                                    onChange={(e) => {
                                                                        if (!float(e.target.value)) {
                                                                            return;
                                                                        }
                                                                        this.setState({ service_charge: e.target.value })
                                                                    }}
                                                                    value={this.state.service_charge}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-md-4 px-1">
                                                            {/* สถานะการชำระส่วนต่าง */}
                                                            <div className="w-100 mb-3">
                                                                <label className="w-100 mb-1">สถานะการชำระส่วนต่าง :</label><br />
                                                                <Select
                                                                    className="form-control validate select-search"
                                                                    styles={select_style}
                                                                    options={[
                                                                        { value: "ชำระ", label: "ชำระ" },
                                                                        { value: "ไม่ชำระ", label: "ไม่ชำระ" }
                                                                    ]}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            status_pay: e,
                                                                        });
                                                                    }}
                                                                    value={this.state.status_pay}
                                                                ></Select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-md-4 px-1">
                                                            {/* หมายเหตุ */}
                                                            <div className="w-100 mb-3">
                                                                <label className="w-100 mb-1">หมายเหตุ :</label><br />
                                                                <Select
                                                                    className="form-control validate select-search"
                                                                    styles={select_style}
                                                                    options={[
                                                                        { value: "รถเสีย", label: "รถเสีย" },
                                                                        { value: "เปลี่ยนรถ", label: "เปลี่ยนรถ" }
                                                                    ]}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            remark: e,
                                                                        });
                                                                    }}
                                                                    value={this.state.remark}
                                                                ></Select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* ขยายสัญญา */}
                                {this.props.location.query.type === "scale" && (
                                    <div className="card border-0">
                                        <div className="card-body p-3">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <label className="w-100 mb-1">วันที่เริ่มต้นการเช่า :</label><br />
                                                            <label className="w-100 mb-1">{format_date(this.state.pick_up_date, "dd/mm/yyyy")} {this.state.pick_up_time}</label>
                                                        </div>
                                                        <div className="col-5">
                                                            <label className="w-100 mb-1">วันที่สิ้นสุดการเช่า :</label><br />
                                                            <label className="w-100 mb-1">{format_date(this.state.return_date, "dd/mm/yyyy")} {this.state.return_time}</label>
                                                        </div>
                                                        <div className="col-2">
                                                            <label className="w-100 mb-1">จำนวน :</label><br />
                                                            <label className="w-100 mb-1">{this.state.days} วัน <label className="text-success">{this.state.return_date_new ? "(+" + days_between(new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")), new Date(format_date(this.state.return_date_new, "yyyy,mm,dd", "en"))) + " วัน)" : ""}</label></label>
                                                        </div>
                                                        <div className="col-12"><hr /></div>
                                                        <div className="col-6 col-md-4">
                                                            {/* วันที่คืนรถใหม่ */}
                                                            <div className="w-100 mb-3">
                                                                <label className="w-100 mb-1">วันที่คืนรถใหม่ :</label><br />
                                                                <DatePickerTH
                                                                    className="form-control validate"
                                                                    placeholder="พิมพ์คำค้นหา..."
                                                                    onChange={(e) => {
                                                                        this.setState({ return_date_new: e });
                                                                        setTimeout(() => {
                                                                            let days = days_between(
                                                                                new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")),
                                                                                new Date(format_date(this.state.return_date_new, "yyyy,mm,dd", "en")),
                                                                                this.state.return_time,
                                                                                this.state.return_time_new
                                                                            )
                                                                            let hours_price = 0;
                                                                            let rental_price = 0;
                                                                            if (days.hour === 0 && days.minute >= 0) {
                                                                                //ไม่ต้องหัก 
                                                                            } else if (days.hour === 1 && days.minute >= 0) {
                                                                                if (this.state.array_price.daily_price < 400) {
                                                                                    hours_price = 50; //หัก 50 บาท
                                                                                } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                                    hours_price = 100; //หัก 100 บาท
                                                                                } else if (this.state.array_price.daily_price > 700) {
                                                                                    hours_price = 200; //หัก 200 บาท
                                                                                }
                                                                            } else if (days.hour === 2 && days.minute >= 0) {
                                                                                if (this.state.array_price.daily_price < 400) {
                                                                                    hours_price = 100; //หัก 50 บาท
                                                                                } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                                    hours_price = 200; //หัก 100 บาท
                                                                                } else if (this.state.array_price.daily_price > 700) {
                                                                                    hours_price = 400; //หัก 200 บาท
                                                                                }
                                                                            } else if (days.hour === 3 && days.minute >= 0) {
                                                                                if (this.state.array_price.daily_price < 400) {
                                                                                    hours_price = 150; //หัก 50 บาท
                                                                                } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                                    hours_price = 300; //หัก 100 บาท
                                                                                } else if (this.state.array_price.daily_price > 700) {
                                                                                    hours_price = 600; //หัก 200 บาท
                                                                                }
                                                                            } else if (days.hour >= 4) {
                                                                                hours_price = this.state.array_price.daily_price;//หัก daily_price
                                                                            }
                                                                            setTimeout(() => {
                                                                                if (this.state.selected_bike) {
                                                                                    if (this.state.rental_type.value === "daily") {
                                                                                        rental_price = this.state.array_price.daily_price * days.day;
                                                                                        rental_price += hours_price;
                                                                                    } else if (this.state.rental_type.value === "weekly") {
                                                                                        if (this.state.array_price.weekly_price === 0) {
                                                                                            rental_price = this.state.array_price.daily_price * days.day;
                                                                                            rental_price += hours_price;
                                                                                        } else {
                                                                                            if (days.day >= 7) {
                                                                                                rental_price += Number((days.day / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                                                                rental_price += (days.day % 7) * this.state.array_price.daily_price;
                                                                                                if (days.day % 7 === 0) {
                                                                                                    rental_price += hours_price;
                                                                                                }
                                                                                            } else {
                                                                                                rental_price = this.state.array_price.weekly_price;
                                                                                            }
                                                                                        }
                                                                                    } else if (this.state.rental_type.value === "monthly") {
                                                                                        if (this.state.array_price.monthly_price === 0) {
                                                                                            rental_price = this.state.array_price.daily_price * days.day;
                                                                                            rental_price += hours_price;
                                                                                        } else {
                                                                                            if (days.day >= 30) {
                                                                                                rental_price += Number((days.day / 30).toString().split(".")[0]) * this.state.array_price.monthly_price;
                                                                                                rental_price += Number(((days.day % 30) / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                                                                rental_price += ((days.day % 30) % 7) * this.state.array_price.daily_price;
                                                                                                if (days.day % 30 === 0) {
                                                                                                    rental_price += hours_price;
                                                                                                }
                                                                                            } else {
                                                                                                rental_price = this.state.array_price.monthly_price;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                                this.setState({ rental_price: rental_price, total_pay: rental_price, total_pay_transfer: 0, total_pay_cradit: 0 })
                                                                            }, 10);
                                                                        }, 10);
                                                                    }}
                                                                    value={this.state.return_date_new}
                                                                    min={new Date(this.state.return_date)}
                                                                    disabled={this.state.rental_status === 12}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4">
                                                            {/* เวลาคืนรถใหม่ */}
                                                            <div className="w-100 mb-3">
                                                                <label className="w-100 mb-1">เวลาคืนรถใหม่ :</label><br />
                                                                <input
                                                                    className="form-control validate"
                                                                    type="time"
                                                                    placeholder="กรอกข้อมูล"
                                                                    onChange={(e) => {
                                                                        this.setState({ return_time_new: e.target.value })
                                                                        setTimeout(() => {
                                                                            let days = days_between(
                                                                                new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")),
                                                                                new Date(format_date(this.state.return_date_new, "yyyy,mm,dd", "en")),
                                                                                this.state.return_time,
                                                                                this.state.return_time_new
                                                                            )
                                                                            let hours_price = 0;
                                                                            let rental_price = 0;
                                                                            if (days.hour === 0 && days.minute >= 0) {
                                                                                //ไม่ต้องหัก 
                                                                            } else if (days.hour === 1 && days.minute >= 0) {
                                                                                if (this.state.array_price.daily_price < 400) {
                                                                                    hours_price = 50; //หัก 50 บาท
                                                                                } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                                    hours_price = 100; //หัก 100 บาท
                                                                                } else if (this.state.array_price.daily_price > 700) {
                                                                                    hours_price = 200; //หัก 200 บาท
                                                                                }
                                                                            } else if (days.hour === 2 && days.minute >= 0) {
                                                                                if (this.state.array_price.daily_price < 400) {
                                                                                    hours_price = 100; //หัก 50 บาท
                                                                                } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                                    hours_price = 200; //หัก 100 บาท
                                                                                } else if (this.state.array_price.daily_price > 700) {
                                                                                    hours_price = 400; //หัก 200 บาท
                                                                                }
                                                                            } else if (days.hour === 3 && days.minute >= 0) {
                                                                                if (this.state.array_price.daily_price < 400) {
                                                                                    hours_price = 150; //หัก 50 บาท
                                                                                } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                                    hours_price = 300; //หัก 100 บาท
                                                                                } else if (this.state.array_price.daily_price > 700) {
                                                                                    hours_price = 600; //หัก 200 บาท
                                                                                }
                                                                            } else if (days.hour >= 4) {
                                                                                hours_price = this.state.array_price.daily_price;//หัก daily_price
                                                                            }
                                                                            setTimeout(() => {
                                                                                if (this.state.selected_bike) {
                                                                                    if (this.state.rental_type.value === "daily") {
                                                                                        rental_price = this.state.array_price.daily_price * days.day;
                                                                                        rental_price += hours_price;
                                                                                    } else if (this.state.rental_type.value === "weekly") {
                                                                                        if (this.state.array_price.weekly_price === 0) {
                                                                                            rental_price = this.state.array_price.daily_price * days.day;
                                                                                            rental_price += hours_price;
                                                                                        } else {
                                                                                            if (days.day >= 7) {
                                                                                                rental_price += Number((days.day / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                                                                rental_price += (days.day % 7) * this.state.array_price.daily_price;
                                                                                                if (days.day % 7 === 0) {
                                                                                                    rental_price += hours_price;
                                                                                                }
                                                                                            } else {
                                                                                                rental_price = this.state.array_price.weekly_price;
                                                                                            }
                                                                                        }
                                                                                    } else if (this.state.rental_type.value === "monthly") {
                                                                                        if (this.state.array_price.monthly_price === 0) {
                                                                                            rental_price = this.state.array_price.daily_price * days.day;
                                                                                            rental_price += hours_price;
                                                                                        } else {
                                                                                            if (days.day >= 30) {
                                                                                                rental_price += Number((days.day / 30).toString().split(".")[0]) * this.state.array_price.monthly_price;
                                                                                                rental_price += Number(((days.day % 30) / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                                                                rental_price += ((days.day % 30) % 7) * this.state.array_price.daily_price;
                                                                                                if (days.day % 30 === 0) {
                                                                                                    rental_price += hours_price;
                                                                                                }
                                                                                            } else {
                                                                                                rental_price = this.state.array_price.monthly_price;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                                this.setState({ rental_price: rental_price, total_pay: rental_price, total_pay_transfer: 0, total_pay_cradit: 0 })
                                                                            }, 10);
                                                                        }, 10);
                                                                    }}
                                                                    value={this.state.return_time_new}
                                                                    disabled={this.state.rental_status === 12}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4">
                                                            {/* ประเภทการเช่า */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-2">ประเภทการเช่า :</label><br />
                                                                <Select
                                                                    className="form-control select-search bg-white mb-2"
                                                                    styles={select_style}
                                                                    options={[{ value: "daily", label: "รายวัน" }, { value: "weekly", label: "รายสัปดาห์" }, { value: "monthly", label: "รายเดือน" }]}
                                                                    onChange={(e) => {
                                                                        this.setState({ rental_type: e });
                                                                        setTimeout(() => {
                                                                            let days = days_between(
                                                                                new Date(format_date(this.state.return_date, "yyyy,mm,dd", "en")),
                                                                                new Date(format_date(this.state.return_date_new, "yyyy,mm,dd", "en")),
                                                                                this.state.return_time,
                                                                                this.state.return_time_new
                                                                            )
                                                                            let hours_price = 0;
                                                                            let rental_price = 0;
                                                                            if (days.hour === 0 && days.minute >= 0) {
                                                                                //ไม่ต้องหัก 
                                                                            } else if (days.hour === 1 && days.minute >= 0) {
                                                                                if (this.state.array_price.daily_price < 400) {
                                                                                    hours_price = 50; //หัก 50 บาท
                                                                                } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                                    hours_price = 100; //หัก 100 บาท
                                                                                } else if (this.state.array_price.daily_price > 700) {
                                                                                    hours_price = 200; //หัก 200 บาท
                                                                                }
                                                                            } else if (days.hour === 2 && days.minute >= 0) {
                                                                                if (this.state.array_price.daily_price < 400) {
                                                                                    hours_price = 100; //หัก 50 บาท
                                                                                } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                                    hours_price = 200; //หัก 100 บาท
                                                                                } else if (this.state.array_price.daily_price > 700) {
                                                                                    hours_price = 400; //หัก 200 บาท
                                                                                }
                                                                            } else if (days.hour === 3 && days.minute >= 0) {
                                                                                if (this.state.array_price.daily_price < 400) {
                                                                                    hours_price = 150; //หัก 50 บาท
                                                                                } else if (this.state.array_price.daily_price >= 400 && this.state.array_price.daily_price <= 700) {
                                                                                    hours_price = 300; //หัก 100 บาท
                                                                                } else if (this.state.array_price.daily_price > 700) {
                                                                                    hours_price = 600; //หัก 200 บาท
                                                                                }
                                                                            } else if (days.hour >= 4) {
                                                                                hours_price = this.state.array_price.daily_price;//หัก daily_price
                                                                            }
                                                                            setTimeout(() => {
                                                                                if (this.state.selected_bike) {
                                                                                    if (this.state.rental_type.value === "daily") {
                                                                                        rental_price = this.state.array_price.daily_price * days.day;
                                                                                        rental_price += hours_price;
                                                                                    } else if (this.state.rental_type.value === "weekly") {
                                                                                        if (this.state.array_price.weekly_price === 0) {
                                                                                            rental_price = this.state.array_price.daily_price * days.day;
                                                                                            rental_price += hours_price;
                                                                                        } else {
                                                                                            if (days.day >= 7) {
                                                                                                rental_price += Number((days.day / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                                                                rental_price += (days.day % 7) * this.state.array_price.daily_price;
                                                                                                if (days.day % 7 === 0) {
                                                                                                    rental_price += hours_price;
                                                                                                }
                                                                                            } else {
                                                                                                rental_price = this.state.array_price.weekly_price;
                                                                                            }
                                                                                        }
                                                                                    } else if (this.state.rental_type.value === "monthly") {
                                                                                        if (this.state.array_price.monthly_price === 0) {
                                                                                            rental_price = this.state.array_price.daily_price * days.day;
                                                                                            rental_price += hours_price;
                                                                                        } else {
                                                                                            if (days.day >= 30) {
                                                                                                rental_price += Number((days.day / 30).toString().split(".")[0]) * this.state.array_price.monthly_price;
                                                                                                rental_price += Number(((days.day % 30) / 7).toString().split(".")[0]) * this.state.array_price.weekly_price;
                                                                                                rental_price += ((days.day % 30) % 7) * this.state.array_price.daily_price;
                                                                                                if (days.day % 30 === 0) {
                                                                                                    rental_price += hours_price;
                                                                                                }
                                                                                            } else {
                                                                                                rental_price = this.state.array_price.monthly_price;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                                this.setState({ rental_price: rental_price, total_pay: rental_price, total_pay_transfer: 0, total_pay_cradit: 0 })
                                                                            }, 10);
                                                                        }, 10);
                                                                    }}
                                                                    value={this.state.rental_type}
                                                                    isDisabled={this.state.readonly || this.state.rental_status === 12}
                                                                ></Select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        {/* คิดค่าขยายสัญญา */}
                                                        <div className="w-100 mb-3">
                                                            <label className="w-100 mb-1">คิดค่าขยายสัญญา :</label><br />
                                                            <Select
                                                                className="form-control validate select-search"
                                                                styles={select_style}
                                                                options={[
                                                                    { value: "คิดเงิน", label: "คิดเงิน" },
                                                                    { value: "ไม่คิดเงิน", label: "ไม่คิดเงิน" }
                                                                ]}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        status_pay2: e,
                                                                        payment_type: 1,
                                                                        bank_name: "",
                                                                        payment_date: "",
                                                                        payment_time: "",
                                                                        total_pay: "",
                                                                        total_pay_transfer: "",
                                                                        total_pay_cradit: "",
                                                                        use_point: false,
                                                                        point: "",
                                                                        use_sk_wallet: false,
                                                                        sk_wallet: "",
                                                                        status_pay: { value: "ชำระ", label: "ชำระ" },
                                                                        discount_pay: 0
                                                                    });
                                                                }}
                                                                value={this.state.status_pay2}
                                                                isDisabled={this.state.rental_status === 12}
                                                            ></Select>
                                                        </div>
                                                    </div>
                                                    {this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน" && (
                                                        <div className="col-12">
                                                            {/* สถานะการชำระ */}
                                                            <div className="w-100 mb-3">
                                                                <label className="w-100 mb-1">สถานะการชำระ :</label><br />
                                                                <Select
                                                                    className="form-control validate select-search"
                                                                    styles={select_style}
                                                                    options={[
                                                                        { value: "ชำระ", label: "ชำระ" },
                                                                        { value: "ค้างชำระ", label: "ค้างชำระ" }
                                                                    ]}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            status_pay: e,
                                                                        });
                                                                    }}
                                                                    value={this.state.status_pay}
                                                                    isDisabled={this.state.rental_status === 12}
                                                                ></Select>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {/* หมายเหตุ */}
                                                    <div className="w-100 mb-3">
                                                        <label className="w-100 mb-1">หมายเหตุ :</label><br />
                                                        <textarea
                                                            className="form-control validate"
                                                            placeholder="กรอกข้อมูล"
                                                            onChange={(e) => {
                                                                this.setState({ remark: e.target.value })
                                                            }}
                                                            value={this.state.remark}
                                                            disabled={this.state.rental_status === 12}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* คืนรถ */}
                                {this.props.location.query.type === "return" && (
                                    <div className="card border-0">
                                        <div className="card-body p-3">
                                            <div className="w-100 d-flex mb-3 border-bottom">
                                                {this.state.tab_return === 0 ? (
                                                    <div className="bg-contain text-orange p-3 text-center pointer w-50"
                                                        style={{ borderTopWidth: 5, borderTopColor: "#fd7e14", borderTopStyle: "solid" }}
                                                        onClick={() => {
                                                            this.setState({ tab_return: 0 })
                                                        }}>รายละเอียดการคืนสินค้า</div>
                                                ) : (
                                                    <div className="bg-white text-dark p-3 text-center pointer w-50"
                                                        style={{ borderTopWidth: 5, borderTopColor: "#ffffff00", borderTopStyle: "solid" }}
                                                        onClick={() => {
                                                            this.setState({ tab_return: 0 })
                                                        }}>รายละเอียดการคืนสินค้า</div>
                                                )}

                                                {this.state.tab_return === 1 ? (
                                                    <div className="bg-contain text-orange p-3 text-center pointer w-50"
                                                        style={{ borderTopWidth: 5, borderTopColor: "#fd7e14", borderTopStyle: "solid" }}
                                                        onClick={() => {
                                                            this.setState({ tab_return: 1 })
                                                        }}>สภาพรถ</div>
                                                ) : (
                                                    <div className="bg-white text-dark p-3 text-center pointer w-50"
                                                        style={{ borderTopWidth: 5, borderTopColor: "#ffffff00", borderTopStyle: "solid" }}
                                                        onClick={() => {
                                                            this.setState({ tab_return: 1 })
                                                        }}>สภาพรถ</div>
                                                )}
                                            </div>
                                            {this.state.tab_return === 0 && (
                                                <div className="col-12">
                                                    <div className="row mb-3">
                                                        <div className="col-12">
                                                            <h4><b>ยอดเงินมัดจำลูกค้า</b></h4>
                                                            <div className="table-responsive">
                                                                <table className="table table-borderless table-striped">
                                                                    <thead>
                                                                        <tr className="bg-dark text-white">
                                                                            <th className="text-center">เลขที่ใบมัดจำ</th>
                                                                            <th className="text-center">วันที่ขยายสัญญา</th>
                                                                            <th className="text-center">ยอดมัดจำ</th>
                                                                            <th className="text-center">สถานะ</th>
                                                                            <th className="text-center">หมายเหตุ</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {(!this.state.data_detail || this.state.data_detail.length === 0) && (
                                                                            <tr> <td colSpan={6} className="text-center"> ไม่มีข้อมูล </td> </tr>
                                                                        )}
                                                                        {this.state.data_detail && this.state.data_detail.map((item, index) => (
                                                                            <tr key={index}>
                                                                                <td className="text-center">{item.payment_number ? item.payment_number : "-"}</td>
                                                                                <td className="text-center">{item.event_extend ? format_date(item.event_extend.return_date_new) + " " + item.event_extend.return_time_new : "-"}</td>
                                                                                <td className="text-center">{item.total_price ? toFixed(item.total_price) : "-"}</td>
                                                                                <td className="text-center">
                                                                                    {item.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || item.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ" ? (
                                                                                        <span className="bg-warning rounded px-2 py-1 text-white">ยังไม่ชำระ</span>
                                                                                    ) : (
                                                                                        <span className="bg-success rounded px-2 py-1 text-white">ชำระแล้ว</span>
                                                                                    )}
                                                                                </td>
                                                                                <td className="text-center">{item.event_extend ? item.event_extend.remark : "-"}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-3">
                                                            {/* ราคาเช่ารถ */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-1">ราคาเช่ารถ :</label><br />
                                                                <input
                                                                    className="form-control validate"
                                                                    type="text"
                                                                    placeholder="กรอกข้อมูล"
                                                                    onChange={(e) => {
                                                                        this.setState({ rental_price: e.target.value })
                                                                    }}
                                                                    value={this.state.rental_price}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-3">
                                                            {/* ประเภทการค้ำประกัน */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-1">ประเภทการค้ำประกัน :</label><br />
                                                                <input
                                                                    className="form-control validate"
                                                                    type="text"
                                                                    placeholder="กรอกข้อมูล"
                                                                    onChange={(e) => {
                                                                        this.setState({ guarantee_amount: e.target.value })
                                                                    }}
                                                                    value={this.state.guarantee_type &&
                                                                        this.state.guarantee_type.value === "1" ?
                                                                        "เงินประกัน" :
                                                                        this.state.guarantee_type.value === "2" ?
                                                                            "สำเนาบัตรประชาชน" :
                                                                            this.state.guarantee_type.value === "3" ?
                                                                                "บัตรประชาชนตัวจริง" :
                                                                                this.state.guarantee_type.value === "41" ?
                                                                                    "บัตรข้าราชการ" :
                                                                                    this.state.guarantee_type.value === "5" ?
                                                                                        "บัตรนักศึกษา" :
                                                                                        this.state.guarantee_type.value === "6" ?
                                                                                            "พาสปอร์ตตัวจริง" :
                                                                                            this.state.guarantee_type.value === "7" ?
                                                                                                "สำเนาพาสปอร์ต" :
                                                                                                this.state.guarantee_type.value === "8" ?
                                                                                                    "ใบขับขี่" :
                                                                                                    this.state.guarantee_type.value === "9" ?
                                                                                                        "ใบขับขี่ตัวจริง" :
                                                                                                        this.state.guarantee_type.value === "10" ?
                                                                                                            "เงินหยวน ( " + toFixed(this.state.guarantee_money) + " หยวน )" :
                                                                                                            this.state.guarantee_type.value === "11" ?
                                                                                                                "เงินดอลล่า ( " + toFixed(this.state.guarantee_money) + " ดอลล่า )" :
                                                                                                                this.state.guarantee_type.value === "12" ?
                                                                                                                    "เงินยูโร ( " + toFixed(this.state.guarantee_money) + " ยูโร )" :
                                                                                                                    ""}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-3">
                                                            {/* จำนวนเงินค้ำประกัน */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-1">จำนวนเงินค้ำประกัน :</label><br />
                                                                <input
                                                                    className="form-control validate"
                                                                    type="text"
                                                                    placeholder="กรอกข้อมูล"
                                                                    onChange={(e) => {
                                                                        this.setState({ guarantee_amount: e.target.value })
                                                                    }}
                                                                    value={this.state.guarantee_amount}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-3">
                                                            {/* ค่าเช่าเกินกำหนด */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-1">ค่าเช่าเกินกำหนด :</label><br />
                                                                <input
                                                                    className="form-control validate"
                                                                    type="text"
                                                                    placeholder="กรอกข้อมูล"
                                                                    onChange={(e) => {
                                                                        this.setState({ rental_price_overdue: e.target.value })
                                                                    }}
                                                                    value={this.state.rental_price_overdue}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-3">
                                                            {/* ค่าเปลี่ยนสถานที่/รถชำระแล้ว */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-1">ค่าเปลี่ยนสถานที่/รถชำระแล้ว :</label><br />
                                                                <input
                                                                    className="form-control validate"
                                                                    type="text"
                                                                    placeholder="กรอกข้อมูล"
                                                                    readOnly
                                                                    value={this.state.service_charge_pay}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-3">
                                                            {/* ค่าเปลี่ยนสถานที่/รถค้างชำระ */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-1">ค่าเปลี่ยนสถานที่/รถค้างชำระ :</label><br />
                                                                <input
                                                                    className="form-control validate"
                                                                    type="text"
                                                                    placeholder="กรอกข้อมูล"
                                                                    readOnly
                                                                    value={this.state.service_charge_overdue}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-3">
                                                            {/* ค่าน้ำมัน */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-1">ค่าน้ำมัน :</label><br />
                                                                <input
                                                                    className="form-control validate"
                                                                    type="text"
                                                                    placeholder="กรอกข้อมูล"
                                                                    onChange={(e) => {
                                                                        if (!float(e.target.value)) {
                                                                            return;
                                                                        }
                                                                        this.setState({ fuel_cost: e.target.value })
                                                                        setTimeout(() => {
                                                                            this.setState({
                                                                                total_pay: (
                                                                                    (Number(this.state.fuel_cost) +
                                                                                        Number(this.state.damage_cost) +
                                                                                        Number(this.state.service_charge) +
                                                                                        Number(this.state.other_expenses) -
                                                                                        Number(this.state.discount_pay) -
                                                                                        Number(this.state.guarantee_amount) -
                                                                                        (Number(this.state.point) * 20) -
                                                                                        Number(this.state.sk_wallet) +
                                                                                        Number(this.state.rental_price_overdue) +
                                                                                        (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)) < 0 ? 0 : Number(this.state.fuel_cost) +
                                                                                        Number(this.state.damage_cost) +
                                                                                        Number(this.state.service_charge) +
                                                                                        Number(this.state.other_expenses) -
                                                                                        Number(this.state.discount_pay) -
                                                                                        Number(this.state.guarantee_amount) -
                                                                                        (Number(this.state.point) * 20) -
                                                                                        Number(this.state.sk_wallet) +
                                                                                        Number(this.state.rental_price_overdue) +
                                                                                    (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
                                                                                ),
                                                                                total_pay_transfer: 0,
                                                                                total_pay_cradit: 0
                                                                            })
                                                                        }, 10);
                                                                    }}
                                                                    value={this.state.fuel_cost}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-3">
                                                            {/* ค่าเสียหาย */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-1">ค่าเสียหาย :</label><br />
                                                                <input
                                                                    className="form-control validate"
                                                                    type="text"
                                                                    placeholder="กรอกข้อมูล"
                                                                    onChange={(e) => {
                                                                        if (!float(e.target.value)) {
                                                                            return;
                                                                        }
                                                                        this.setState({ damage_cost: e.target.value })
                                                                        setTimeout(() => {
                                                                            this.setState({
                                                                                total_pay: (
                                                                                    (Number(this.state.fuel_cost) +
                                                                                        Number(this.state.damage_cost) +
                                                                                        Number(this.state.service_charge) +
                                                                                        Number(this.state.other_expenses) -
                                                                                        Number(this.state.discount_pay) -
                                                                                        Number(this.state.guarantee_amount) -
                                                                                        (Number(this.state.point) * 20) -
                                                                                        Number(this.state.sk_wallet) +
                                                                                        Number(this.state.rental_price_overdue) +
                                                                                        (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)) < 0 ? 0 : Number(this.state.fuel_cost) +
                                                                                        Number(this.state.damage_cost) +
                                                                                        Number(this.state.service_charge) +
                                                                                        Number(this.state.other_expenses) -
                                                                                        Number(this.state.discount_pay) -
                                                                                        Number(this.state.guarantee_amount) -
                                                                                        (Number(this.state.point) * 20) -
                                                                                        Number(this.state.sk_wallet) +
                                                                                        Number(this.state.rental_price_overdue) +
                                                                                    (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
                                                                                ),
                                                                                total_pay_transfer: 0,
                                                                                total_pay_cradit: 0
                                                                            })
                                                                        }, 10);
                                                                    }}
                                                                    value={this.state.damage_cost}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-3">
                                                            {/* ค่าบริการรับส่ง */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-1">ค่าบริการรับส่ง :</label><br />
                                                                <input
                                                                    className="form-control validate"
                                                                    type="text"
                                                                    placeholder="กรอกข้อมูล"
                                                                    onChange={(e) => {
                                                                        if (!float(e.target.value)) {
                                                                            return;
                                                                        }
                                                                        this.setState({ service_charge: e.target.value })
                                                                        setTimeout(() => {
                                                                            this.setState({
                                                                                total_pay: (
                                                                                    (Number(this.state.fuel_cost) +
                                                                                        Number(this.state.damage_cost) +
                                                                                        Number(this.state.service_charge) +
                                                                                        Number(this.state.other_expenses) -
                                                                                        Number(this.state.discount_pay) -
                                                                                        Number(this.state.guarantee_amount) -
                                                                                        (Number(this.state.point) * 20) -
                                                                                        Number(this.state.sk_wallet) +
                                                                                        Number(this.state.rental_price_overdue) +
                                                                                        (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)) < 0 ? 0 : Number(this.state.fuel_cost) +
                                                                                        Number(this.state.damage_cost) +
                                                                                        Number(this.state.service_charge) +
                                                                                        Number(this.state.other_expenses) -
                                                                                        Number(this.state.discount_pay) -
                                                                                        Number(this.state.guarantee_amount) -
                                                                                        (Number(this.state.point) * 20) -
                                                                                        Number(this.state.sk_wallet) +
                                                                                        Number(this.state.rental_price_overdue) +
                                                                                    (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
                                                                                ),
                                                                                total_pay_transfer: 0,
                                                                                total_pay_cradit: 0
                                                                            })
                                                                        }, 10);
                                                                    }}
                                                                    value={this.state.service_charge}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-3">
                                                            {/* ค่าอื่นๆ */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-1">ค่าอื่นๆ :</label><br />
                                                                <input
                                                                    className="form-control validate"
                                                                    type="text"
                                                                    placeholder="กรอกข้อมูล"
                                                                    onChange={(e) => {
                                                                        if (!float(e.target.value)) {
                                                                            return;
                                                                        }
                                                                        this.setState({ other_expenses: e.target.value })
                                                                        setTimeout(() => {
                                                                            this.setState({
                                                                                total_pay: (
                                                                                    (Number(this.state.fuel_cost) +
                                                                                        Number(this.state.damage_cost) +
                                                                                        Number(this.state.service_charge) +
                                                                                        Number(this.state.other_expenses) -
                                                                                        Number(this.state.discount_pay) -
                                                                                        Number(this.state.guarantee_amount) -
                                                                                        (Number(this.state.point) * 20) -
                                                                                        Number(this.state.sk_wallet) +
                                                                                        Number(this.state.rental_price_overdue) +
                                                                                        (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)) < 0 ? 0 : Number(this.state.fuel_cost) +
                                                                                        Number(this.state.damage_cost) +
                                                                                        Number(this.state.service_charge) +
                                                                                        Number(this.state.other_expenses) -
                                                                                        Number(this.state.discount_pay) -
                                                                                        Number(this.state.guarantee_amount) -
                                                                                        (Number(this.state.point) * 20) -
                                                                                        Number(this.state.sk_wallet) +
                                                                                        Number(this.state.rental_price_overdue) +
                                                                                    (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
                                                                                ),
                                                                                total_pay_transfer: 0,
                                                                                total_pay_cradit: 0
                                                                            })
                                                                        }, 10);
                                                                    }}
                                                                    value={this.state.other_expenses}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-3">
                                                            {/* ส่วนลด */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-1">ส่วนลด :</label><br />
                                                                <input
                                                                    className="form-control validate"
                                                                    type="text"
                                                                    placeholder="กรอกข้อมูล"
                                                                    onChange={(e) => {
                                                                        if (!float(e.target.value)) {
                                                                            return;
                                                                        }
                                                                        this.setState({ discount_pay: e.target.value })
                                                                        setTimeout(() => {
                                                                            this.setState({
                                                                                total_pay: (
                                                                                    (Number(this.state.fuel_cost) +
                                                                                        Number(this.state.damage_cost) +
                                                                                        Number(this.state.service_charge) +
                                                                                        Number(this.state.other_expenses) -
                                                                                        Number(this.state.discount_pay) -
                                                                                        Number(this.state.guarantee_amount) -
                                                                                        (Number(this.state.point) * 20) -
                                                                                        Number(this.state.sk_wallet) +
                                                                                        Number(this.state.rental_price_overdue) +
                                                                                        (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)) < 0 ? 0 : Number(this.state.fuel_cost) +
                                                                                        Number(this.state.damage_cost) +
                                                                                        Number(this.state.service_charge) +
                                                                                        Number(this.state.other_expenses) -
                                                                                        Number(this.state.discount_pay) -
                                                                                        Number(this.state.guarantee_amount) -
                                                                                        (Number(this.state.point) * 20) -
                                                                                        Number(this.state.sk_wallet) +
                                                                                        Number(this.state.rental_price_overdue) +
                                                                                    (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
                                                                                ),
                                                                                total_pay_transfer: 0,
                                                                                total_pay_cradit: 0
                                                                            })
                                                                        }, 10);
                                                                    }}
                                                                    value={this.state.discount_pay}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            {/* หมายเหตุ */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-2">หมายเหตุ :</label><br />
                                                                <textarea
                                                                    className="form-control validate"
                                                                    placeholder="กรอกข้อมูล"
                                                                    rows={5}
                                                                    value={this.state.return_remark}
                                                                    onChange={(e) => { this.setState({ return_remark: e.target.value }) }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {this.state.tab_return === 1 && (
                                                <div className="card-body p-3">
                                                    <div className="d-flex justify-content-between">
                                                        <h4 className="mb-0"><b>สภาพรถ</b></h4>
                                                    </div>
                                                    <hr />
                                                    <div className="w-100 pt-3">
                                                        <div className="row mx-0">
                                                            <div className="col-12 mr-auto">
                                                                <div className="row">
                                                                    {this.state.detail_picture.filter(e => e.length !== 0).map((item, index) => (
                                                                        <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                                            <div
                                                                                className="w-100 hpx-150 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-2"
                                                                                onClick={() => {
                                                                                    this.setState({ index: index, modal_picture: true, modal_return: false })
                                                                                }}
                                                                            >
                                                                                <div className="position-relative w-100 hpx-150 d-flex justify-content-center align-items-center">
                                                                                    <img alt="รูปโปรไฟล์" src={item.includes("https://") ? item : "data:image/png;base64," + item} className="w-100 hpx-140 rounded overflow-hidden" style={{ objectFit: "contain" }}></img>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* ขวา */}
                            <div className="col-12 col-lg-5 mb-3">
                                {this.state.rental_status === 0 ? (
                                    <div className="card border-0 mb-3 p-3">
                                        <h4><b>ข้อมูลรถ</b></h4>
                                        <hr />
                                        <div className="w-100 mb-3">
                                            <h6><b>รายละเอียดรถ</b></h6>
                                            <div className="row">
                                                <div className="col-12 d-flex mb-2">
                                                    <b className="wpx-50">ยี่ห้อ</b><label>: {this.state.selected_bike ? this.state.selected_bike.brand_name : "-"}</label>
                                                </div>
                                                <div className="col-6 d-flex mb-2">
                                                    <b className="wpx-50">ชื่อรุ่น</b><label>: {this.state.selected_bike ? this.state.selected_bike.model_name : "-"}</label>
                                                </div>
                                                <div className="col-6 d-flex mb-2">
                                                    <b className="wpx-50">ทะเบียน</b><label>: {this.state.selected_bike ? this.state.selected_bike.license_plate : "-"}</label>
                                                </div>
                                                <div className="col-6 d-flex mb-2">
                                                    <b className="wpx-50">สี</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_color : "-"}</label>
                                                </div>
                                                <div className="col-6 d-flex mb-2">
                                                    <b className="wpx-50">ปี</b><label>: {this.state.selected_bike ? this.state.selected_bike.bike_year + 543 : "-"}</label>
                                                </div>
                                                <div className="col-6 d-flex mb-2">
                                                    <b className="wpx-100">หมายเลขรถ</b><label>: {this.state.selected_bike ? this.state.selected_bike.channel_name : "-"}</label>
                                                </div>
                                            </div>
                                            <hr />
                                            <h6><b>วันที่รับ / คืนรถ</b></h6>
                                            <div className="d-flex justify-content-between">
                                                <div className="w-100">
                                                    <b className="wpx-40">วันที่รับรถ</b>
                                                    <label className="ml-2">{this.state.pick_up_date ? format_date(this.state.pick_up_date, "dd mmm yyyy") : "-"} {this.state.pick_up_time ? this.state.pick_up_time : "-"}</label><br />
                                                    <b className="my-2">สถานที่รับรถ</b>
                                                    <label className="ml-2 text-orange">{this.state.pick_up_location ? this.state.pick_up_location : "-"} </label>
                                                </div>
                                                <div className="wpx-60 d-flex justify-content-center align-items-center">
                                                    <label className="icon text-24 text-orange">{"\uf356"}</label>
                                                </div>
                                                <div className="w-100">
                                                    <b className="wpx-40">วันที่คืนรถ</b>
                                                    <label className="ml-2">{this.state.return_date ? format_date(this.state.return_date, "dd mmm yyyy") : "-"} {this.state.return_time ? this.state.return_time : "-"}</label><br />
                                                    <b className="my-2">สถานที่คืนรถ</b>
                                                    <label className="ml-2 text-orange">{this.state.return_location ? this.state.return_location : "-"} </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>) : (
                                    <div>
                                        <div className="card border-0 px-3 py-2">
                                            <div className="row">
                                                <div className="col-12 mb-3">
                                                    <label className="w-100 mb-2">เงินสด :</label><br />
                                                    <input
                                                        className="form-control  validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return;
                                                            }
                                                            this.setState({
                                                                total_pay: Number(e.target.value)
                                                            })
                                                        }}
                                                        value={this.state.total_pay ? this.state.total_pay : 0}
                                                        disabled={this.state.rental_status === 1 || this.state.rental_status === 6 || (this.state.status_pay2 && this.state.status_pay2.value === "ไม่คิดเงิน")}
                                                    />
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <label className="w-100 mb-2">บัตรเครดิต :</label><br />
                                                    <input
                                                        className="form-control  validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return;
                                                            }
                                                            this.setState({
                                                                total_pay_cradit: Number(e.target.value)
                                                            })
                                                        }}
                                                        value={this.state.total_pay_cradit}
                                                        disabled={this.state.rental_status === 1 || this.state.rental_status === 6 || (this.state.status_pay2 && this.state.status_pay2.value === "ไม่คิดเงิน")}
                                                    />
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <label className="w-100 mb-2">เงินโอน :</label><br />
                                                    <input
                                                        className="form-control  validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return;
                                                            }
                                                            this.setState({
                                                                total_pay_transfer: Number(e.target.value)
                                                            })
                                                        }}
                                                        value={this.state.total_pay_transfer}
                                                        disabled={this.state.rental_status === 1 || this.state.rental_status === 6 || (this.state.status_pay2 && this.state.status_pay2.value === "ไม่คิดเงิน")}
                                                    />
                                                </div>

                                                <div className="col-12">
                                                    {/* บัญชี */}
                                                    <div className="w-100 mb-2">
                                                        <label className="w-100 mb-2">บัญชี :</label><br />
                                                        <Select
                                                            className="form-control select-search bg-white mb-2"
                                                            styles={select_style}
                                                            options={this.state.data_bank ? this.state.data_bank : []}
                                                            onChange={(e) => {
                                                                this.setState({ bank_name: e });
                                                            }}
                                                            value={this.state.bank_name}
                                                            isDisabled={this.state.rental_status === 1 || this.state.rental_status === 6}
                                                        ></Select>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    {/* วันที่โอน */}
                                                    <div className="w-100 mb-2">
                                                        <label className="w-100 mb-2">วันที่โอน :</label><br />
                                                        <DatePickerTH
                                                            className="form-control"
                                                            placeholder="พิมพ์คำค้นหา..."
                                                            onChange={(e) => {
                                                                this.setState({ payment_date: e });
                                                            }}
                                                            value={this.state.payment_date}
                                                            disabled={this.state.rental_status === 1 || this.state.rental_status === 6}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    {/* เวลาที่โอน */}
                                                    <div className="w-100 mb-2">
                                                        <label className="w-100 mb-2">เวลาที่โอน :</label><br />
                                                        <input
                                                            className="form-control validate"
                                                            type="time"
                                                            placeholder="กรอกข้อมูล"
                                                            value={this.state.payment_time}
                                                            onChange={(e) => { this.setState({ payment_time: e.target.value }) }}
                                                            disabled={this.state.rental_status === 1 || this.state.rental_status === 6}
                                                        />
                                                    </div>
                                                </div>
                                                {(this.props.location.query.type === "scale" && this.state.status_pay2 && this.state.status_pay2.value === "คิดเงิน") && (
                                                    <div className="col-12">
                                                        {/* ส่วนลด */}
                                                        <div className="w-100 mb-2">
                                                            <label className="w-100 mb-2">ส่วนลด :</label><br />
                                                            <input
                                                                className="form-control validate"
                                                                type="text"
                                                                placeholder="กรอกข้อมูล"
                                                                onChange={(e) => {
                                                                    if (!float(e.target.value)) {
                                                                        return;
                                                                    }
                                                                    this.setState({ discount_pay: e.target.value })
                                                                }}
                                                                value={this.state.discount_pay}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="row">
                                                <input className="d-none" type="file" id="file_sub2" accept="image/*" onChange={(e) => { this.import_file2(e, "sub2") }} multiple />
                                                {this.state.payment_picture.map((item, index) => (
                                                    <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                        <div
                                                            className="w-100 hpx-150 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-2"
                                                        >
                                                            <div className="position-relative w-100 hpx-150 d-flex justify-content-center align-items-center">
                                                                <img
                                                                    alt="รูปภาพสลิปหลักฐาน"
                                                                    src={item.picture.includes("https://") ? item.picture : "data:image/png;base64," + item.picture}
                                                                    className="w-100 hpx-140 rounded overflow-hidden"
                                                                    style={{ objectFit: "contain" }}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            modal_picture2: true,
                                                                            index: index
                                                                        })
                                                                    }}
                                                                ></img>
                                                                {!(this.state.rental_status === 1 || this.state.rental_status === 6 || (this.state.status_pay2 && this.state.status_pay2.value === "ไม่คิดเงิน")) && (
                                                                    <button className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle" style={{ top: -8, right: -8 }} onClick={() => {
                                                                        let payment_picture = this.state.payment_picture;
                                                                        payment_picture.splice(index, 1);
                                                                        this.setState({ payment_picture: payment_picture })
                                                                    }}>
                                                                        {"\uf00d"}
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                {this.state.payment_picture.length === 0 && !(this.state.rental_status === 1 || this.state.rental_status === 6 || (this.state.status_pay2 && this.state.status_pay2.value === "ไม่คิดเงิน")) ? (
                                                    <div
                                                        className="w-100 hpx-150 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                                                        onClick={() => { document.getElementById("file_sub2").click() }}
                                                    >
                                                        <label className="icon text-orange">{"\uf03e"}</label>
                                                        <label className="text-orange text-12">รูปภาพสลิปหลักฐาน</label>
                                                    </div>
                                                ) : !(this.state.rental_status === 1 || this.state.rental_status === 6 || (this.state.status_pay2 && this.state.status_pay2.value === "ไม่คิดเงิน")) ? (
                                                    <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                                                        <div
                                                            className="w-100 hpx-150 pointer card border-2 border-orange d-flex align-items-center justify-content-center px-3 py-5"
                                                            onClick={() => { document.getElementById("file_sub2").click() }}
                                                        >
                                                            <label className="icon text-orange">{"\uf03e"}</label>
                                                            <label className="text-orange text-12">รูปภาพสลิปหลักฐาน</label>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="card border-0 px-3 py-2">
                                            {this.state.wallet ? (
                                                <div className="row mx-0">
                                                    <div className="col-12 px-1">
                                                        {/* ใช้แต้ม */}
                                                        <div className="d-flex align-items-center w-100 mb-2">
                                                            <label className="mr-2 wpx-80">ใช้แต้ม :</label>
                                                            <div className="d-flex align-items-center">
                                                                <Switch onChange={(e) => {
                                                                    this.setState({
                                                                        use_point: e,
                                                                        point: 0
                                                                    })
                                                                }} checked={this.state.use_point}
                                                                    disabled={this.state.rental_status === 1 || this.state.rental_status === 6} />
                                                                <label className="mx-2">แต้มสะสม <b>{toFixed(this.state.wallet.point)}</b> แต้ม</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.use_point && (
                                                        <div className="col-12 col-sm-6 px-1">
                                                            {/* จำนวนแต้ม */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-2">จำนวนแต้ม : <small>(10 แต้ม เท่ากับ 200 บาท)</small></label><br />
                                                                <input
                                                                    className="form-control validate"
                                                                    type="text"
                                                                    placeholder="กรอกข้อมูล"
                                                                    onChange={(e) => {
                                                                        if (!float(e.target.value)) {
                                                                            return;
                                                                        }
                                                                        if (Number(e.target.value) > this.state.wallet.point) {
                                                                            e.target.value = this.state.wallet.point;
                                                                        }
                                                                        this.setState({ point: e.target.value })
                                                                    }}
                                                                    onBlur={() => {
                                                                        let point = 0;
                                                                        if (Number(this.state.point) % 10 !== 0) {
                                                                            point = Number(this.state.point) - (Number(this.state.point) % 10);
                                                                        } else {
                                                                            point = this.state.point;
                                                                        }
                                                                        this.setState({ point: point });
                                                                    }}
                                                                    value={this.state.point}
                                                                    disabled={this.state.rental_status === 1 || this.state.rental_status === 6}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {this.state.use_point && (
                                                        <div className="col-12 col-sm-6 px-1">
                                                            {/* แลกส่วนลดได้ */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-2">แลกส่วนลดได้ :</label><br />
                                                                <input
                                                                    className="form-control validate"
                                                                    type="text"
                                                                    placeholder="กรอกข้อมูล"
                                                                    value={toFixed(Number(this.state.point) * 20)}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : null}
                                            <hr />
                                            {this.state.wallet ? (
                                                <div className="row mx-0">
                                                    <div className="col-12 px-1">
                                                        {/* ใช้เงินสะสม */}
                                                        <div className="d-flex align-items-center w-100 mb-2">
                                                            <label className="mr-2 wpx-80">ใช้เงินสะสม :</label>
                                                            <div className="d-flex align-items-center">
                                                                <Switch onChange={(e) => {
                                                                    this.setState({
                                                                        use_sk_wallet: e,
                                                                        sk_wallet: 0
                                                                    })
                                                                }} checked={this.state.use_sk_wallet}
                                                                    disabled={this.state.rental_status === 1 || this.state.rental_status === 6} />
                                                                <label className="mx-2">เงินสะสม <b>{toFixed(this.state.wallet.sk_wallet)}</b> บาท</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.use_sk_wallet && (
                                                        <div className="col-12 col-sm-6 px-1">
                                                            {/* ต้องการใช้เงินสะสม */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-2">ต้องการใช้เงินสะสม :</label><br />
                                                                <input
                                                                    className="form-control validate"
                                                                    type="text"
                                                                    placeholder="กรอกข้อมูล"
                                                                    onChange={(e) => {
                                                                        if (!float(e.target.value)) {
                                                                            return;
                                                                        }
                                                                        if (Number(e.target.value) > this.state.wallet.sk_wallet) {
                                                                            e.target.value = this.state.wallet.sk_wallet;
                                                                        }
                                                                        this.setState({ sk_wallet: e.target.value })
                                                                    }}
                                                                    value={this.state.sk_wallet}
                                                                    disabled={this.state.rental_status === 1 || this.state.rental_status === 6}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {this.state.use_sk_wallet && (
                                                        <div className="col-12 col-sm-6 px-1">
                                                            {/* แลกส่วนลดได้ */}
                                                            <div className="w-100 mb-2">
                                                                <label className="w-100 mb-2">แลกส่วนลดได้ :</label><br />
                                                                <input
                                                                    className="form-control validate"
                                                                    type="text"
                                                                    placeholder="กรอกข้อมูล"
                                                                    value={toFixed(Number(this.state.sk_wallet))}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>)}
                                {(this.state.rental_status === 0 || this.state.rental_status === 6 || this.props.location.query.type === "return") && this.state.agent_id ? (
                                    <div className="card border-0 mt-3">
                                        <div className="p-3">
                                            <div className="d-flex justify-content-between">
                                                <h4><b>ค่าแนะนำ</b></h4>
                                                {this.state.status_bill_agent && (
                                                    <span className="status-rent-10 rounded px-2 py-1 hpx-30 text-white">เบิกค่าแนะนำแล้ว</span>
                                                )}
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-6 mb-3">
                                                    <label className="w-100 mb-3">เงินค่าแนะนำ :</label><br />
                                                    <input
                                                        className="form-control  validate"
                                                        type="text"
                                                        placeholder="กรอกข้อมูล"
                                                        onChange={(e) => {
                                                            if (!float(e.target.value)) {
                                                                return;
                                                            }
                                                            this.setState({
                                                                agent_total_pay: Number(e.target.value),
                                                            })
                                                        }}
                                                        value={this.state.agent_total_pay}
                                                        readOnly={this.state.status_bill_agent}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 mb-3">
                                                    <label className="w-100 mb-3">ประเภทการจ่าย :</label><br />
                                                    <Select
                                                        className="form-control select-search bg-white mb-2"
                                                        styles={select_style}
                                                        options={[
                                                            { value: "1", label: "เงินสด" },
                                                            { value: "2", label: "เงินโอน" }
                                                        ]}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                agent_payment_type: e,
                                                            });
                                                        }}
                                                        value={this.state.agent_payment_type}
                                                        isDisabled={this.state.status_bill_agent}
                                                    ></Select>
                                                </div>
                                            </div>
                                            {/* ลายมือชื่ิอผู้เช่า */}

                                            <div>
                                                <div className="d-flex w-100 justify-content-between">
                                                    <h4 className="mb-0"><b>ลายเซ็นต์ผู้รับเงิน</b></h4>
                                                    {this.state.admin_signature && !this.state.status_bill_agent ? (
                                                        <button className="btn btn-orange" onClick={() => {
                                                            Swal.fire({
                                                                icon: "warning",
                                                                title: "แจ้งเตือน",
                                                                text: "ยืนยันการล้างลายมือชื่ิอผู้เช่าหรือไม่",
                                                                confirmButtonText: "ตกลง",
                                                                cancelButtonText: "ยกเลิก",
                                                                showCancelButton: true,
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    this.setState({ admin_signature: "" })
                                                                }
                                                            });
                                                        }}>ล้างลายมือ</button>
                                                    ) : null}
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-12">
                                                        {this.state.admin_signature === "" ? (
                                                            <div className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center pointer" style={{ height: 250 }} onClick={() => { if (!this.state.status_bill_agent) { this.setState({ modal_signature: true }) } }}>
                                                                <label className="icon text-orange">{"\uf03e"}</label>
                                                                <label className="text-orange">ลายเซ็นต์</label>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center p-2"
                                                            >
                                                                <div className="position-relative w-100 p-4">
                                                                    <img alt="รูปลายเซ็นต์" src={this.state.admin_signature.includes("https://") ? this.state.admin_signature : "data:image/png;base64," + this.state.admin_signature} className="w-100 img-fluid" style={{ height: 250, objectFit: "contain" }}></img>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            {/* สรุป */}
                            <div className="col-12 mb-3">
                                <div className="card bg-orange px-3 py-2">
                                    {this.state.rental_status === 0 && (
                                        <div className="row">
                                            <div className="col-6 px-1">
                                                <div className="card bg-white p-2">
                                                    <div className="text-center">
                                                        <label className="mb-2">ชำระแล้ว</label>
                                                        <h2 className="mx-2 mb-0 text-success">
                                                            {toFixed(this.state.rental_price + this.state.guarantee_amount + this.state.total_price_equipment + this.state.delivery_price + this.state.other_price - this.state.discount_price + this.state.deposit_price + this.state.service_charge)}
                                                        </h2>
                                                        <label>บาท</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 px-1">
                                                <div className="card bg-white p-2">
                                                    <div className="text-center">
                                                        <label className="mb-2">ค้างชำระ</label>
                                                        <h2 className="mx-2 mb-0">0.00
                                                        </h2>
                                                        <label>บาท</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.rental_status === 1 && (
                                        <div className="row">
                                            <div className="col-6 col-md-4 px-1 mb-2">
                                                <div className="card bg-white p-2">
                                                    <div className="text-center">
                                                        <label className="mb-2">ชำระแล้ว</label>
                                                        <h2 className="mx-2 mb-0 text-success">
                                                            {toFixed((this.state.rental_price_pay ? this.state.rental_price + this.state.service_charge : 0) + this.state.guarantee_amount + this.state.total_price_equipment + this.state.delivery_price + this.state.other_price - this.state.discount_price + this.state.deposit_price)}
                                                        </h2>
                                                        <label>บาท</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-4 px-1 mb-2">
                                                <div className="card bg-white p-2">
                                                    <div className="text-center">
                                                        <label className="mb-2">ค้างชำระ</label>
                                                        <h2 className="mx-2 mb-0">{toFixed(!this.state.rental_price_pay ? this.state.rental_price + this.state.service_charge : 0)}
                                                        </h2>
                                                        <label>บาท</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 px-1 mb-2">
                                                <div className="card bg-white p-2">
                                                    <div className="text-center">
                                                        <label className="mb-2">เงินทอน</label>
                                                        <h2 className="mx-2 mb-0">{toFixed(((Number(this.state.total_pay) + Number(this.state.total_pay_cradit) + Number(this.state.total_pay_transfer)) + (this.state.point * 20) + this.state.sk_wallet) - (this.state.rental_price + this.state.guarantee_amount + this.state.total_price_equipment + this.state.delivery_price + this.state.other_price - this.state.discount_price + this.state.deposit_price + this.state.service_charge))}
                                                        </h2>
                                                        <label>บาท</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    )}
                                    {this.state.rental_status === 2 && (
                                        <div className="row">
                                            <div className="col-6 col-md-4 px-1 mb-2">
                                                <div className="card bg-white p-2">
                                                    <div className="text-center">
                                                        <label className="mb-2">ชำระแล้ว</label>
                                                        <h2 className="mx-2 mb-0 text-success">
                                                            {toFixed(this.state.total_pay_booking)}
                                                        </h2>
                                                        <label>บาท</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-4 px-1 mb-2">
                                                <div className="card bg-white p-2">
                                                    <div className="text-center">
                                                        <label className="mb-2">ค้างชำระ</label>
                                                        <h2 className="mx-2 mb-0"> {toFixed(this.state.total_price)}
                                                        </h2>
                                                        <label>บาท</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 px-1 mb-2">
                                                <div className="card bg-white p-2">
                                                    <div className="text-center">
                                                        <label className="mb-2">เงินทอน</label>
                                                        <h2 className="mx-2 mb-0"> {toFixed((Number(this.state.total_pay) + Number(this.state.total_pay_cradit) + Number(this.state.total_pay_transfer)) + (Number(this.state.point) * 20) + Number(this.state.sk_wallet) - Number(this.state.total_price))}
                                                        </h2>
                                                        <label>บาท</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.rental_status === 6 && (
                                        <div className="row">
                                            <div className="col-6 col-md-3 px-1 mb-2">
                                                <div className="d-flex">
                                                    <div className="w-50">
                                                        <label className="text-white mb-1">วันที่เช่า</label>
                                                        <h3 className="text-white"><b>
                                                            {this.state.days} วัน {this.state.hours} ชั่วโมง {this.state.minutes} นาที
                                                        </b></h3>
                                                    </div>
                                                    <div className="w-50">
                                                        <label className="text-white mb-1">ประเภทการเช่า</label>
                                                        <h5 className="text-white mt-2"><b>
                                                            {
                                                                this.state.rental_type.value === "daily" ? "รายวัน" :
                                                                    this.state.rental_type.value === "weekly" ? "รายสัปดาห์ (7วัน)" :
                                                                        this.state.rental_type.value === "monthly" ? "รายเดือน (30วัน)" : "-"
                                                            }
                                                        </b></h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-3 px-1 mb-2">
                                                <div className="card bg-white p-2">
                                                    <div className="text-center">
                                                        <label className="mb-2">ยอดเงินคืนก่อนกำหนด (เข้าเงินสะสม)</label>
                                                        <h2 className="mx-2 mb-0 text-success">{toFixed(this.state.refund_cost)}
                                                        </h2>
                                                        <label>บาท</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-3 px-1 mb-2">
                                                <div className="card bg-white p-2">
                                                    <div className="text-center">
                                                        <label className="mb-2">ชำระแล้ว</label>
                                                        <h2 className="mx-2 mb-0 text-success">
                                                            {
                                                                (
                                                                    Number(this.state.fuel_cost) +
                                                                    Number(this.state.damage_cost) +
                                                                    Number(this.state.service_charge) +
                                                                    Number(this.state.other_expenses) -
                                                                    Number(this.state.discount_pay) +
                                                                    Number(this.state.rental_price_overdue) +
                                                                    (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
                                                                ) < 0 ? "0.00" :
                                                                    toFixed(
                                                                        Number(this.state.fuel_cost) +
                                                                        Number(this.state.damage_cost) +
                                                                        Number(this.state.service_charge) +
                                                                        Number(this.state.other_expenses) -
                                                                        Number(this.state.discount_pay) +
                                                                        Number(this.state.rental_price_overdue) +
                                                                        (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
                                                                    )
                                                            }
                                                        </h2>
                                                        <label>บาท</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-3 px-1 mb-2">
                                                <div className="card bg-white p-2">
                                                    <div className="text-center">
                                                        <label className="mb-2">เงินทอน</label>
                                                        <h2 className="mx-2 mb-0">0.00
                                                        </h2>
                                                        <label>บาท</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.rental_status === 9 && (
                                        <div className="row">
                                            <div className="col-6 col-md-4 text-center mb-2">
                                                <label className="text-white mb-1">ยอดเงินคืนก่อนกำหนด</label>
                                                <div className="w-100 card bg-white text-center">
                                                    <h3 className="text-orange"><b>
                                                        {
                                                            toFixed(Number(this.state.refund_cost))
                                                        }
                                                    </b></h3>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-4 text-center mb-2">
                                                <label className="text-white mb-1">ยอดชำระเงินทั้งหมด</label>
                                                <div className="w-100 card bg-white text-center">
                                                    <h3 className="text-orange">
                                                        <b>
                                                            {
                                                                (this.state.status_pay && this.state.status_pay.value === "ชำระ") ?
                                                                    toFixed((this.state.rental_price - this.state.old_price) + Number(this.state.service_charge)) :
                                                                    toFixed(Number(this.state.service_charge))
                                                            }
                                                        </b>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 text-center mb-2">
                                                <label className="text-white mb-1">เงินทอน</label>
                                                <h3 className="text-white"><b>
                                                    {
                                                        (this.state.status_pay && this.state.status_pay.value === "ชำระ") ?
                                                            toFixed(((Number(this.state.total_pay) + Number(this.state.total_pay_cradit) + Number(this.state.total_pay_transfer)) + (Number(this.state.point) * 20) + Number(this.state.sk_wallet)) - ((this.state.rental_price - this.state.old_price) + (Number(this.state.service_charge)))) :
                                                            toFixed(((Number(this.state.total_pay) + Number(this.state.total_pay_cradit) + Number(this.state.total_pay_transfer)) + (Number(this.state.point) * 20) + Number(this.state.sk_wallet)) - (Number(this.state.service_charge)))
                                                    }
                                                </b></h3>
                                            </div>
                                        </div>
                                    )}
                                    {this.props.location.query.type === "scale" && (
                                        <div className="row">
                                            <div className="col-6 col-md-4 px-1 mb-2">
                                                <div className="card bg-white p-2">
                                                    <div className="text-center">
                                                        <label className="mb-2">ชำระแล้ว</label>
                                                        <h2 className="mx-2 mb-0 text-success">
                                                            0.00
                                                        </h2>
                                                        <label>บาท</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-4 px-1 mb-2">
                                                <div className="card bg-white p-2">
                                                    <div className="text-center">
                                                        <label className="mb-2">ค้างชำระ</label>
                                                        <h2 className="mx-2 mb-0"> {this.state.status_pay2 && this.state.status_pay2.value === "ไม่คิดเงิน" ? "0.00" : toFixed(Number(this.state.rental_price) - Number(this.state.discount_pay))}
                                                        </h2>
                                                        <label>บาท</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 px-1 mb-2">
                                                <div className="card bg-white p-2">
                                                    <div className="text-center">
                                                        <label className="mb-2">เงินทอน</label>
                                                        <h2 className="mx-2 mb-0">{this.state.status_pay2 && this.state.status_pay2.value === "ไม่คิดเงิน" ? "0.00" : toFixed((Number(this.state.total_pay) + Number(this.state.total_pay_cradit) + Number(this.state.total_pay_transfer)) - (Number(this.state.rental_price) - (Number(this.state.point) * 20) - Number(this.state.sk_wallet) - Number(this.state.discount_pay)))}
                                                        </h2>
                                                        <label>บาท</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {this.props.location.query.type === "return" && (
                                        <div className="row">
                                            <div className="col-6 col-md-3 px-1 mb-2">
                                                <div className="d-flex">
                                                    <div className="w-50">
                                                        <label className="text-white mb-1">วันที่เช่า</label>
                                                        <h3 className="text-white"><b>
                                                            {this.state.days} วัน {this.state.hours} ชั่วโมง {this.state.minutes} นาที
                                                        </b></h3>
                                                    </div>
                                                    <div className="w-50">
                                                        <label className="text-white mb-1">ประเภทการเช่า</label>
                                                        <h5 className="text-white mt-2"><b>
                                                            {
                                                                this.state.rental_type.value === "daily" ? "รายวัน" :
                                                                    this.state.rental_type.value === "weekly" ? "รายสัปดาห์ (7วัน)" :
                                                                        this.state.rental_type.value === "monthly" ? "รายเดือน (30วัน)" : "-"
                                                            }
                                                        </b></h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-3 px-1 mb-2">
                                                <div className="card bg-white p-2">
                                                    <div className="text-center">
                                                        <label className="mb-2">ยอดเงินคืนก่อนกำหนด (เข้าเงินสะสม)</label>
                                                        <h2 className="mx-2 mb-0 text-success">{toFixed(this.state.refund_cost)}
                                                        </h2>
                                                        <label>บาท</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-3 px-1 mb-2">
                                                <div className="card bg-white p-2">
                                                    <div className="text-center">
                                                        <label className="mb-2">ยอดชำระเงินทั้งหมด</label>
                                                        <h2 className="mx-2 mb-0 text-success">
                                                            {
                                                                (
                                                                    Number(this.state.fuel_cost) +
                                                                    Number(this.state.damage_cost) +
                                                                    Number(this.state.service_charge) +
                                                                    Number(this.state.service_charge_overdue) +
                                                                    Number(this.state.other_expenses) -
                                                                    Number(this.state.discount_pay) +
                                                                    Number(this.state.rental_price_overdue) +
                                                                    (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
                                                                ) < 0 ? "0.00" :
                                                                    toFixed(
                                                                        Number(this.state.fuel_cost) +
                                                                        Number(this.state.damage_cost) +
                                                                        Number(this.state.service_charge) +
                                                                        Number(this.state.service_charge_overdue) +
                                                                        Number(this.state.other_expenses) -
                                                                        Number(this.state.discount_pay) +
                                                                        Number(this.state.rental_price_overdue) +
                                                                        (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
                                                                    )
                                                            }
                                                        </h2>
                                                        <label>บาท</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-3 px-1 mb-2">
                                                <div className="card bg-white p-2">
                                                    <div className="text-center">
                                                        <label className="mb-2">เงินทอน</label>
                                                        <h2 className="mx-2 mb-0"> {
                                                            toFixed(
                                                                (Number(this.state.total_pay) + Number(this.state.total_pay_cradit) + Number(this.state.total_pay_transfer)) - (
                                                                    Number(this.state.fuel_cost) +
                                                                    Number(this.state.damage_cost) +
                                                                    Number(this.state.service_charge) +
                                                                    Number(this.state.service_charge_overdue) +
                                                                    Number(this.state.other_expenses) -
                                                                    Number(this.state.discount_pay) -
                                                                    Number(this.state.guarantee_amount) -
                                                                    (Number(this.state.point) * 20) -
                                                                    Number(this.state.sk_wallet) +
                                                                    Number(this.state.rental_price_overdue) +
                                                                    (this.state.data_detail ? total(this.state.data_detail.filter(e => e.admin_remark === "พนักงาน ขยายสัญญาไม่ชำระ" || e.admin_remark === "แคชเชียร์ ขยายสัญญาไม่ชำระ"), "total_price") : 0)
                                                                )
                                                            )

                                                        }
                                                        </h2>
                                                        <label>บาท</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* ปุ่ม */}
                            <div className="col-12 mb-3">
                                {this.state.rental_status === 0 && !this.state.status_bill_agent && (
                                    <button className="btn btn-dark w-100"
                                        onClick={() => { this.Agent(); }}>เบิกค่าแนะนำ
                                    </button>
                                )}
                                {this.state.rental_status === 1 && (
                                    <button className="btn btn-dark w-100"
                                        onClick={() => { this.Update01(); }}>ยืนยันการชำระ
                                    </button>
                                )}
                                {this.state.rental_status === 2 && (
                                    <button className="btn btn-dark w-100"
                                        onClick={() => { this.Update02(); }}>ชำระเงิน
                                    </button>
                                )}
                                {this.state.rental_status === 6 && (
                                    <button
                                        className="btn btn-dark w-100"
                                        onClick={() => {
                                            this.ReturnCar10();
                                        }}
                                    >
                                        ยืนยันการคืนรถ
                                    </button>
                                )}
                                {this.props.location.query.type === "scale" && (
                                    <div>
                                        {this.state.rental_status === 3 && (
                                            <button
                                                className="btn btn-dark w-100"
                                                onClick={() => {
                                                    if (!this.state.status_pay) {
                                                        alert("warning", "แจ้งเตือน", "กรุณาเลือกสถานะการชำระ");
                                                        return;
                                                    }
                                                    if ((this.state.status_pay && this.state.status_pay.value === "ชำระ")) {
                                                        this.Scale01();
                                                    } else {
                                                        this.Scale02();
                                                    }
                                                }}
                                            >
                                                ขยายสัญญา
                                            </button>
                                        )}
                                        {this.state.rental_status === 4 && (
                                            <button
                                                className="btn btn-dark w-100"
                                                onClick={() => {

                                                    if (!this.state.status_pay) {
                                                        alert("warning", "แจ้งเตือน", "กรุณาเลือกสถานะการชำระ");
                                                        return;
                                                    }
                                                    if ((this.state.status_pay && this.state.status_pay.value === "ชำระ")) {
                                                        this.Scale01();
                                                    } else {
                                                        this.Scale02();
                                                    }
                                                }}
                                            >
                                                ขยายสัญญา
                                            </button>
                                        )}
                                        {this.state.rental_status === 5 && (
                                            <div className="w-100 d-flex">
                                                <div className="w-100 p-1">
                                                    <button
                                                        className="btn btn-warning w-100"
                                                        onClick={() => {
                                                            this.Scale04();
                                                        }}
                                                    >
                                                        รอเงินมัดจำ
                                                    </button>
                                                </div>
                                                <div className="w-100 p-1">
                                                    <button
                                                        className="btn btn-danger w-100"
                                                        onClick={() => {
                                                            this.Scale03();
                                                        }}
                                                    >
                                                        ไม่อนุมัติ
                                                    </button>
                                                </div>
                                                <div className="w-100 p-1">
                                                    <button
                                                        className="btn btn-success w-100"
                                                        onClick={() => {
                                                            if (!this.state.status_pay) {
                                                                alert("warning", "แจ้งเตือน", "กรุณาเลือกสถานะการชำระ");
                                                                return;
                                                            }
                                                            if ((this.state.status_pay && this.state.status_pay.value === "ชำระ")) {
                                                                this.Scale01();
                                                            } else {
                                                                this.Scale02();
                                                            }
                                                        }}
                                                    >
                                                        อนุมัติ
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        {this.state.rental_status === 7 && (
                                            <button
                                                className="btn btn-dark w-100"
                                                onClick={() => {
                                                    if (!this.state.status_pay) {
                                                        alert("warning", "แจ้งเตือน", "กรุณาเลือกสถานะการชำระ");
                                                        return;
                                                    }
                                                    if ((this.state.status_pay && this.state.status_pay.value === "ชำระ")) {
                                                        this.Scale01();
                                                    } else {
                                                        this.Scale02();
                                                    }
                                                }}
                                            >
                                                ขยายสัญญา
                                            </button>
                                        )}
                                        {this.state.rental_status === 8 && (
                                            <button
                                                className="btn btn-dark w-100"
                                                onClick={() => {
                                                    if (!this.state.status_pay) {
                                                        alert("warning", "แจ้งเตือน", "กรุณาเลือกสถานะการชำระ");
                                                        return;
                                                    }
                                                    if ((this.state.status_pay && this.state.status_pay.value === "ชำระ")) {
                                                        this.Scale01();
                                                    } else {
                                                        this.Scale02();
                                                    }
                                                }}
                                            >
                                                ขยายสัญญา
                                            </button>
                                        )}
                                    </div>
                                )}
                                {this.props.location.query.type === "return" && (
                                    <button
                                        className="btn btn-dark w-100"
                                        onClick={() => {
                                            this.ReturnCar10();
                                        }}
                                    >
                                        ยืนยันการคืนรถ
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* ลายมือชื่อผู้เช่า */}
                <Modal show={this.state.modal_signature} >
                    <Modal.Header> <Modal.Title> <b>ลายมือชื่อผู้เช่า</b> </Modal.Title> </Modal.Header>
                    <Modal.Body>
                        <div className="w-100 card border-2 border-orange d-flex align-items-center justify-content-center">
                            <SignatureCanvas
                                penColor='black'
                                canvasProps={{ height: 500, className: 'w-100 sigCanvas' }}
                                clearOnResize={false}
                                ref={(ref) => { this.sigPad = ref }}
                                onEnd={(e) => { this.trim() }} />
                        </div>
                        {/* ปุ่ม */}
                        <div className="w-100 d-flex">
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-outline-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_signature: false, admin_signature: "" })
                                    }}
                                >
                                    ยกเลิก
                                </button>
                            </div>
                            <div className="w-50 p-1">
                                <button
                                    className="btn btn-orange w-100"
                                    onClick={() => {
                                        this.setState({ modal_signature: false })
                                    }}
                                >
                                    บันทึก
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* picture */}
                <Modal show={this.state.modal_picture} onHide={() => { this.setState({ modal_picture: false, modal_detail: true }) }} size={"xl"}>
                    <Modal.Header><Modal.Title><b>สภาพรถ</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="d-flex align-items-center justify-content-between">
                            <div
                                className="wpx-40 hpx-40 bg-orange text-white d-flex justify-content-center align-items-center pointer"
                                onClick={() => {
                                    let index = this.state.index;
                                    if (index === 0) {
                                        index = this.state.detail_picture.filter(item => item !== "").length - 1;
                                    } else {
                                        index -= 1;
                                    }
                                    this.setState({ index: index })
                                }}>
                                {"<"}
                            </div>
                            {this.state.detail_picture ? (
                                <img src={this.state.detail_picture[this.state.index]} className="w-75 mb-3" style={{ height: 500, objectFit: "contain" }}></img>
                            ) : null}
                            <div
                                className="wpx-40 hpx-40 bg-orange text-white d-flex justify-content-center align-items-center pointer"
                                onClick={() => {
                                    let index = this.state.index;
                                    if (index === this.state.detail_picture.filter(item => item !== "").length - 1) {
                                        index = 0;
                                    } else {
                                        index += 1;
                                    }
                                    this.setState({ index: index })
                                }}>
                                {">"}
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center overflow-scroll">
                            {this.state.detail_picture.filter(item => item !== "").map((item, index) => (
                                <div className={this.state.index === index ? "wpx-100 hpx-100 card border-2 border-orange d-flex align-items-center justify-content-center overflow-hidden" : "wpx-100 hpx-100 d-flex align-items-center justify-content-center overflow-hidden"}>
                                    <img src={item} className="w-100 mb-3 wpx-100 hpx-100 mx-2 pointer" style={{ objectFit: "contain" }} onClick={() => { this.setState({ index: index }) }}></img>
                                </div>
                            ))}
                        </div>
                        <div className="w-100 d-flex justify-content-center mt-3">
                            <button className="btn btn-outline-orange px-5" onClick={() => { this.setState({ modal_picture: false, modal_return: true }) }}>
                                ปิดหน้าต่าง
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* picture */}
                <Modal show={this.state.modal_picture2}
                    onHide={() => {
                        this.setState({ modal_picture2: false });
                    }} size={"xl"}>
                    <Modal.Header><Modal.Title><b>รูปสลิป</b></Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="d-flex align-items-center justify-content-between">
                            <div
                                className="wpx-40 hpx-40 bg-orange text-white d-flex justify-content-center align-items-center pointer"
                                onClick={() => {
                                    let index = this.state.index;
                                    if (index === 0) {
                                        index = this.state.payment_picture.length - 1;
                                    } else {
                                        index -= 1;
                                    }
                                    this.setState({ index: index })
                                }}>
                                {"<"}
                            </div>
                            {this.state.payment_picture && this.state.payment_picture[this.state.index] ? (
                                <img src={this.state.payment_picture[this.state.index].picture} className="w-75 mb-3" style={{ height: 500, objectFit: "contain" }}></img>
                            ) : null}
                            <div
                                className="wpx-40 hpx-40 bg-orange text-white d-flex justify-content-center align-items-center pointer"
                                onClick={() => {
                                    let index = this.state.index;
                                    if (index === this.state.payment_picture.length - 1) {
                                        index = 0;
                                    } else {
                                        index += 1;
                                    }
                                    this.setState({ index: index })
                                }}>
                                {">"}
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center overflow-scroll">
                            {this.state.payment_picture.map((item, index) => (
                                <div className={this.state.index === index ? "wpx-100 hpx-100 card border-2 border-orange d-flex align-items-center justify-content-center overflow-hidden" : "wpx-100 hpx-100 d-flex align-items-center justify-content-center overflow-hidden"}>
                                    <img src={item.picture} className="w-100 mb-3 wpx-100 hpx-100 mx-2 pointer" style={{ objectFit: "contain" }} onClick={() => { this.setState({ index: index }) }}></img>
                                </div>
                            ))}
                        </div>
                        <div className="w-100 d-flex justify-content-center mt-3">
                            <button className="btn btn-outline-orange px-5"
                                onClick={() => {
                                    this.setState({ modal_picture2: false });
                                }}>
                                ปิดหน้าต่าง
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}
